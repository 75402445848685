import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollTop from "./ScrollTop.js";
import Content from "../components/ContentHeader.js";
import Route from "../components/Route.js";

// css
import "../css/RPA_Portal.css";

//img
import intro_icon1 from "../assets/img/chat_icon1.png";
import intro_icon2 from "../assets/img/chat_icon2.png";
import intro_icon3 from "../assets/img/chat_icon3.png";
import quotes_left from "../assets/img/icon_quotes_left.png";
import quotes_right from "../assets/img/icon_quotes_right.png";
import buttonArrow from "../assets/img/button_arrow_icon.png";
import download from "../assets/img/download_icon.png";
import bg1 from "../assets/img/main_bg1.png";
import bg2 from "../assets/img/main_bg2.png";
import solarImg from "../assets/img/solarImg.png";
import LLMIcon1 from "../assets/img/chat_LLM_icon1.png";
import LLMIcon2 from "../assets/img/chat_LLM_icon2.png";
import LLMIcon3 from "../assets/img/chat_LLM_icon3.png";
import LLMIcon4 from "../assets/img/chat_LLM_icon4.png";
import LLMIcon5 from "../assets/img/chat_LLM_icon5.png";
import searchIcon from "../assets/img/icon_search.png";
import arrowBtn from "../assets/img/button_arrow_icon.png";
// import LLMex1 from "../assets/img/LLM_ex1.png";
import LLMex2 from "../assets/img/LLM_ex2.png";

function EurekaGenAI() {
  const handleCmpanyProfile = async () => {
    window.open(
      "http://file.rbrain.co.kr/2024-10-15T01-27-51-918Z-%EC%9C%A0%EB%A0%88%EC%B9%B4RAG%20%EC%A0%9C%ED%92%88%EC%86%8C%EA%B0%9C%EC%84%9C.pdf",
      "_blank"
    );
  };

  const data = [
    {
      title: "서비스 기능​",
      items: [
        "사용자가 DB로 구축되는 문서를 등록하고 업무에 관하여 질의할 수 있습니다.",
      ],
      description:
        "파인튜닝한 LLM을 통해 사용자 의도를 파악하여 질문 의도에 맞는 명확한 답변을 합니다.<br /><br />서비스 확장이 유연하여 도입 시 커스텀 기능을 개발할 수 있습니다.<br /><br />기업 환경에서 생성형 AI 적용을 위한 End-to-End 솔루션으로 업무 지원이 가능합니다.​​",
    },
    {
      title: "ETL 기능​​",
      items: [
        "DB 구축을 위한 지식 기반(Knowledge Base) 데이터를 추출 및 변환할 수 있습니다.",
      ],
      description:
        "PDF, DOCX, HTML 등 다양한 문서 형식을 지원합니다.<br /><br />DB 구축 과정에서 문맥과 문장 의미를 유지하여 AI가 더 정확하게 답변할 수 있습니다.<br /><br />필요에 따라 수집기 또는 RPA 연동 개발이 가능합니다.",
    },
    {
      title: "검색 기능​​",
      items: [
        "사용자 질문과 관련된 지식 기반(Knowledge Base) DB를 검색합니다.",
      ],
      description:
        "유사도 검색 기술을 통해 DB를 정확하게 참조하며, 필요에 따라 미세조정이 가능합니다.<br /><br />테이블 전처리 기술을 통해 테이블 자체를 참조할 수 있습니다.<br /><br />다양한 도메인에 특화된 온프레미스 검색 모델을 제공할 수 있습니다.",
    },
    {
      title: "답변 생성 기능​​",
      items: [
        "지식 기반(Knowledge Base) DB를 참조하여 사용자 질문에 적절한 답변을 생성합니다.",
      ],
      description:
        "프롬프트 엔지니어링을 통해 지정된 형식으로 일관성 있는 답변합니다.<br /><br />민감한 개인 정보를 솔루션에서 자체 필터링할 수 있습니다.<br /><br />각 도메인에 맞게 응답하는 특화된 모델을 제공할 수 있습니다.",
    },
  ];

  return (
    <div className="portal_wrap eureka_wrap">
      <ScrollTop />

      {/* 페이지 타이틀명 설정 */}
      <Helmet>
        <title>레인보우브레인 | 지능형자동화 전문기업</title>
        <meta
          name="description"
          content="지식 기반 데이터로 업무를 지원하는 기업 특화 AI 솔루션을 소개합니다."
        ></meta>
      </Helmet>

      <Content title="유레카GenAI 솔루션"></Content>

      <Route route1="BUSINESS" route2="EurekaGenAI"></Route>

      <div className="content_bg">
        <div className="container portal_intro">
          <h2 className="heading1">서비스 소개</h2>

          <div className="portal_intro_img chat_intro_img">
            <ul className="portal_intro_wrap">
              <li className="portal_intro_item">
                <img src={intro_icon1} alt="icon1" />

                <p className="sub_title3">지식 기반 DB 구축​</p>
              </li>

              <li className="portal_intro_item">
                <img src={intro_icon2} alt="icon2" />

                <p className="sub_title3">
                  도메인 특화
                  <br />
                  LLM 서비스 제공
                </p>
              </li>

              <li className="portal_intro_item">
                <img src={intro_icon3} alt="icon3" />

                <p className="sub_title3">
                  인공지능을 통한
                  <br />
                  업무 지원
                </p>
              </li>
            </ul>
          </div>

          <div className="portal_intro_text">
            <h3 className="heading2 text_left">
              유레카GenAI 솔루션은 기업이 보유한 문서와 지식을 AI가 사용할 수
              있도록 지식베이스 데이터베이스로 구축하고, 도메인 전문성을
              가지도록 사전 학습된 기업 특화 생성형 AI 솔루션입니다.
            </h3>

            <p className="sub_title4 text_left">
              유레카GenAI를 통해 산재된 정보를 하나로 통합하여 사용자 의도를
              정확하게 파악한 답변을 함으로써 필요한 정보를 탐색하고 적절한
              문서를 참고하는 시간을 대폭 줄여줍니다.
              <br />
              또한, 기업 내 KB(Knowledge Base)를 활용한
              검색증강생성(Retrieval-Augmented Generation) 기술을 적용하여
              생성형 AI에서 고질적으로 발생하는 문제인 환각화(Hallucination)을
              최소화 하여 서비스를 제공합니다.
            </p>
          </div>

          <div className="blue_btn">
            <span className="quotes quotes_left">
              <img src={quotes_left} alt="quotes_left" />
            </span>

            <p className="sub_title2 text_left">
              성공적인 생성형 AI 기술 도입을 위해 RAG 기반 기업 특화 AI 솔루션,
              <br />
              유레카GenAI로 스마트한 업무 지원 서비스를 구축해보세요!
            </p>

            <span className="quotes quotes_right">
              <img src={quotes_right} alt="quotes_right" />
            </span>
          </div>
        </div>
      </div>

      <div className="intro_video">
        <div className="container">
          <h2 className="heading1">서비스 소개 영상</h2>
          <div className="iframe">
            <iframe
              src="https://www.youtube.com/embed/2AJ_Bqzk-B8?si=Na8MWBbcDGKyz3Pf"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>

          <div>
            <Link
              to="/company/support"
              className="blue_btn button1"
              style={{ marginRight: "20px" }}
            >
              서비스 체험 신청하기
              <img src={buttonArrow} alt="RPA Portal 바로가기" />
            </Link>
            <button className="blue_btn button1" onClick={handleCmpanyProfile}>
              제품소개서 다운로드​
              <img src={download} alt="download" />
            </button>
          </div>
        </div>
        {/* 뒷배경 도형 */}
        <img className="news_bg1 news_bg" src={bg1} alt="bg" />
        <img className="news_bg2 news_bg" src={bg2} alt="bg1" />
      </div>

      <div className="sec3">
        <div className="container">
          <h2 className="heading1">서비스 주요 기능</h2>
          <div className="d-grid">
            {data.map((box, index) => (
              <div className="box" key={index}>
                <div className="header_box sub_title3">
                  {box.title.split("\n").map((line, idx) => (
                    <div key={idx}>{line}</div>
                  ))}
                </div>
                <div className="contents1">
                  {box.items.map((item, idx) => (
                    <p key={idx}>
                      <span className="dot2"></span>
                      {item}
                    </p>
                  ))}
                  <p
                    className="text"
                    dangerouslySetInnerHTML={{ __html: box.description }}
                  ></p>
                </div>
              </div>
            ))}
          </div>

          <h2 className="heading1 pt100">
            서비스 예시
            <br />
            (금융 및 보험사)
          </h2>
          <div className="LLM_ex_box">
            {/* <img src={LLMex1} className="LLMex1" alt="아이콘 이미지" /> */}
            <img src={LLMex2} className="LLMex2" alt="아이콘 이미지" />
          </div>
        </div>
      </div>

      <div className="chat_box">
        <div className="container">
          <div className="chat_box_left">
            <h2 class="heading1 text_left">Solar LLM은</h2>
            <h3 className="heading2 text_left">
              도메인 특화 AI 기술로
              <br />
              슈퍼 휴먼 수준의 지능으로 비즈니스 문제를 해결할 수 있습니다.
            </h3>
            <p className="sub_title4 text_left">
              헬스케어, 고객지원, 금융 및 보험, 법, 미디어 등 목적에 맞게 학습
              시킨 Solar LLM은 레인보우브레인의 검색증강생성(RAG) 기술과
              함께하여 더 정확한 답변을 제공합니다.
              <br />
              <br />
              타의 추종을 불허하는 빠른 스피드와 여러 도메인 및 업무를 처리하는
              다재다능함을 갖춘 Solar LLM을 합리적인 비용으로 도입해보세요!
            </p>

            <a
              href="https://www.upstage.ai/solar-llm?utm_term=About+Solar&utm_content=%2Fhome"
              className="blue_btn button1"
              style={{ marginTop: "30px" }}
              target="blank"
            >
              Solar LLM 보러가기
              <img src={buttonArrow} alt="RPA Portal 바로가기" />
            </a>
          </div>
          <div className="chat_box_right">
            <img src={solarImg} alt="아이콘 이미지" />
          </div>
        </div>
      </div>

      <div className="sec3">
        <ul className="LLM_icon_wrap">
          <li className="portal_intro_item">
            <img src={LLMIcon1} alt="아이콘 이미지" />
            <p className="sub_title3">문서 전처리</p>
          </li>

          <li className="portal_intro_item">
            <img src={LLMIcon2} alt="아이콘 이미지" />
            <p className="sub_title3">지식기반 임베딩 구축​</p>
          </li>

          <li className="portal_intro_item">
            <img src={LLMIcon3} alt="아이콘 이미지" />
            <p className="sub_title3">프롬프트​ 엔지니어링</p>
          </li>

          <li className="portal_intro_item">
            <img src={LLMIcon4} alt="아이콘 이미지" />
            <p className="sub_title3">파인튜닝</p>
          </li>

          <li className="portal_intro_item">
            <img src={LLMIcon5} alt="아이콘 이미지" />
            <p className="sub_title3">Public/Private LLM 연동 지원​</p>
          </li>
        </ul>
      </div>

      <div className="banner_wrap">
        <div className="banner_cont container">
          <div>
            <img src={searchIcon} alt="아이콘 이미지" />
            <p className="contents1">
              유레카GenAI(EurekaGenAI)를 더 알고 싶다면?
            </p>
          </div>

          <a
            href="http://file.rbrain.co.kr/2024-10-15T01-27-51-918Z-%EC%9C%A0%EB%A0%88%EC%B9%B4RAG%20%EC%A0%9C%ED%92%88%EC%86%8C%EA%B0%9C%EC%84%9C.pdf"
            target="blank"
          >
            <button className="blue_btn1 button2">
              유레카GenAI 제품소개서 다운로드
              <img src={download} alt="아이콘 이미지" />
            </button>
          </a>
        </div>

        <div className="banner_cont container">
          <div>
            <img src={searchIcon} alt="아이콘 이미지" />
            <p className="contents1">
              유레카GenAI(EurekaGenAI)를 체험하고 싶다면?
            </p>
          </div>

          <Link to="/company/support">
            <button className="blue_btn1 button2">
              유레카GenAI 서비스 체험 신청하기​
              <img src={arrowBtn} alt="아이콘 이미지" />
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default EurekaGenAI;
