import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Slider from "react-slick";
import Modal from "./Modal";

// css
import "../css/Slider.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// img
import bg from "../assets/img/main_sub_img.jpg";
import buttonArrow from "../assets/img/button_arrow_icon.png";

function MainSlider() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3500,
    // pauseOnHover: true,
    vertical: false,
  };

  const handleSubscribe = async () => {
    setIsModalOpen(true);
  };

  // const handleSubscribe = async () => {
  //   window.open("https://page.stibee.com/subscriptions/146545", "_blank");
  // };

  const handleSubscribe_2 = async () => {
    window.open("http://m.site.naver.com/14r5Z", "_blank");
  };

  return (
    <>
      <div className="slider_container">
        <img src={bg} alt="" />
        <div className="blur"></div>
        <div className="container">
          <Slider {...settings}>
            <div className="slider_text">
              <p className="heading1 slider_title">
                필수가 된 AI 기술 LLM
                <br />
                기업 특화 AI 업무 지원 솔루션 유레카GenAI를 경험해 보세요.
              </p>
              <p className="sub_title4 slider_sub_title1">
                아래 버튼을 클릭하면, 체험 신청 화면이 나타납니다.
              </p>
              <div>
                <NavLink to="/company/support">
                  <button className="outline_btn button1">
                    <span>유레카GenAI 체험 신청하기</span>
                    <img src={buttonArrow} alt="NEWSLETTER" />
                  </button>
                </NavLink>
              </div>
            </div>

            <div className="slider_text">
              <p className="heading1 slider_title">
                지능형자동화 전문기업 레인보우브레인이
                <br />
                생생한 자동화 성공사례와 AI 업계 소식을
                <br />월 1회 📧전해드립니다.
              </p>
              <p className="sub_title4 slider_sub_title1">
                아래 버튼을 클릭하면, NEWSLETTER 구독 신청 화면이 나타납니다.
              </p>
              <div>
                <button
                  className="outline_btn button1"
                  onClick={handleSubscribe}
                >
                  <span>NEWSLETTER 구독하기</span>
                  <img src={buttonArrow} alt="NEWSLETTER" />
                </button>
              </div>
            </div>

            <div className="slider_text">
              <p className="heading1 slider_title">
                RPA 원리가 궁금하신 모든 분들
                <br />
                실습하면서 직접 체험해 보세요!
              </p>
              <p className="sub_title4 slider_sub_title1">
                아래 버튼을 클릭하면, RPA 교육 신청 페이지 화면이 나타납니다.
              </p>
              <div>
                <button
                  className="outline_btn button1"
                  onClick={handleSubscribe_2}
                >
                  <span>RPA 교육 신청하기</span>
                  <img src={buttonArrow} alt="RPA 교육 신청" />
                </button>
              </div>
            </div>
          </Slider>
        </div>
      </div>
      {isModalOpen && <Modal onClose={() => setIsModalOpen(false)} />}
    </>
  );
}

export default MainSlider;
