import contentBg from "../assets/img/content_bg.png";

// -- css -- //
import "../css/ContentHeader.css";

function Content(props) {
  return (
    <>
      <div className='content_title'>
        <img src={contentBg} alt='메인배경' />
        <h1 className='heading1'>{props.title}</h1>
        <h2 className='heading1'>{props.title1}</h2>
      </div>
    </>
  );
}

export default Content;
