import React from "react";
import { Link } from "react-router-dom";
import ScrollTop from "./ScrollTop";

// css
import "../css/App.font.css";
import "../css/AiExpo.css";

// img
import Logo from "../assets/img/logo_white.png";
import arrowBtn from "../assets/img/arrow_icon.png";
import Icon1 from "../assets/img/aiexpo_icon1.png";
import Icon2 from "../assets/img/aiexpo_icon2.png";
import Icon3 from "../assets/img/aiexpo_icon3.png";
import Icon4 from "../assets/img/aiexpo_icon4.png";
import check from "../assets/img/check_icon.png";

function AiExpoLLM() {
  const data = [
    {
      image: Icon1,
      title: "기업 특화 LLM 지원",
      items: (
        <>
          <p>기업 환경에 맞춰</p>
          <p>LLM 파인튜닝 지원​</p>
        </>
      ),
    },
    {
      image: Icon2,
      title: "비정형 자료 DB화",
      items: (
        <>
          <p>기업 내・외부 자료를</p>
          <p>지식 기반 체계로 디지털화​</p>
        </>
      ),
    },
    {
      image: Icon3,
      title: "지식 기반 질의 응답",
      items: (
        <>
          <p>환각화(Hallucination) 방지 및</p>
          <p>질의 응답의 내용 품질 향상</p>
        </>
      ),
    },
    {
      image: Icon4,
      title: "개인정보 보호​",
      items: (
        <>
          <p>개인정보 보호​ 및</p>
          <p>기업정보 외부 유출 차단​</p>
        </>
      ),
    },
  ];

  return (
    <div className="wrap">
      <ScrollTop />

      <div className="header d-flex">
        <div className="expo_container d-between">
          <h1 className="heading1">AI EXPO KOREA 2024</h1>
          <div className="d-flex">
            <img src={Logo} alt="로고 이미지" />
            <p className="contents1">
              <span>&#124;</span>Lead Coexistence of Human and AI in the
              Workflow
            </p>
          </div>
        </div>
      </div>

      <div className="expo_container">
        <div className="main_title d-flex">
          <h1 className="heading2">Eureka LLM & RAG</h1>
          <p className="contents1">
            인공지능이 업무를 지원하는 미래, 멀지 않은 내일! RAG 및 KB 기업 특화
            LLM
          </p>
        </div>
        <div className="iframe_wrap">
          <iframe
            width="1024"
            height="576"
            src="https://www.youtube.com/embed/2AJ_Bqzk-B8?si=2W3NSPpQRFqvEwih"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>

        <div>
          {/* <h1 className="heading2">Features</h1> */}
          <div className="sub_wrap d-flex">
            {data.map((box, index) => (
              <div className="sub_box" key={index}>
                <img src={check} className="check_icon" alt="체크 아이콘" />
                <div className="sub_title4">
                  {box.title.split("\n").map((line, idx) => (
                    <div key={idx}>{line}</div>
                  ))}
                </div>
                <img
                  className="sub_icon"
                  src={box.image}
                  alt={"아이콘 이미지"}
                />
                <div className="contents2">{box.items}</div>
              </div>
            ))}
          </div>
        </div>

        <div className="ai_list_btn">
          <Link to="/aiexpo">
            <button className="btn btn_prev d-flex">
              <img className="prev_icon" src={arrowBtn} alt="prev icon" />
              영상 목록으로 돌아가기
            </button>
          </Link>
          <Link to="/aiexpoECommerce">
            <button className="btn btn_next d-flex">
              Eureka e-Commerce 보러가기
              <img className="next_icon" src={arrowBtn} alt="arrow icon" />
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default AiExpoLLM;
