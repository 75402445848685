import { useRef } from "react";

function ModalInfoProvide({ onClose }) {
  const modalBackground = useRef(); //배경클릭시 꺼지게하려고

  const handleClick = () => {
    if (typeof onClose === "function") {
      onClose();
    }
  };

  return (
    <div className="modal_wrap" ref={modalBackground}>
      <div className="modal_agree_box">
        <h1>개인정보수집 및 이용</h1>
        <p>
          레인보우브레인은 뉴스레터 제공을 위해 이름, 이메일주소를 수집하여
          이용합니다.
          <br />
          수집된 정보는 발송 외 다른 목적으로 이용되지 않으며, 서비스가
          종료되거나 구독을 해지할 경우 즉시 파기됩니다.
        </p>

        <div className="d-end">
          <button className="close sub_btn" onClick={handleClick}>
            닫기
          </button>
        </div>
      </div>
    </div>
  );
}

export default ModalInfoProvide;
