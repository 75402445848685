import React from "react";
import Slider from "react-slick";

//css
import "../css/Client.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//img
import hand from "../assets/img/client_hand.png";
import ABC from "../assets/img/logo/ABC.png";
import AIF from "../assets/img/logo/AIF.png";
import aif02 from "../assets/img/logo/aif02.png";
import amway from "../assets/img/logo/amway.png";
import asan from "../assets/img/logo/asan.png";
import CJ01 from "../assets/img/logo/CJ01.png";
import CJ02 from "../assets/img/logo/CJ02.png";
import ctr from "../assets/img/logo/ctr.png";
import dongwha from "../assets/img/logo/dongwha.png";
import ez from "../assets/img/logo/ez.png";
import FINESET from "../assets/img/logo/FINESET.png";
import geps from "../assets/img/logo/geps.png";
import han from "../assets/img/logo/han.png";
import harim from "../assets/img/logo/harim.png";
import hira from "../assets/img/logo/hira.png";
import hmall01 from "../assets/img/logo/hmall01.png";
import hmall02 from "../assets/img/logo/hmall02.png";
import interlojo from "../assets/img/logo/interlojo.png";
import join from "../assets/img/logo/join.png";
import JVM from "../assets/img/logo/JVM.png";
import k_export from "../assets/img/logo/k_export.png";
import k_sure from "../assets/img/logo/k_sure.png";
import KET from "../assets/img/logo/KET.png";
import knps from "../assets/img/logo/knps.png";
import kosme from "../assets/img/logo/kosme.png";
import LeeKo from "../assets/img/logo/LeeKo.png";
import lotte_Capital from "../assets/img/logo/lotte_Capital.png";
import lotte_chemical from "../assets/img/logo/lotte_chemical.png";
import lotte_fine from "../assets/img/logo/lotte_fine.png";
import lotte_himart from "../assets/img/logo/lotte_himart.png";
import megamart from "../assets/img/logo/megamart.png";
import monalisa from "../assets/img/logo/monalisa.png";
import NDS from "../assets/img/logo/NDS.png";
import nice from "../assets/img/logo/nice.png";
import nice02 from "../assets/img/logo/nice02.png";
import nongshim from "../assets/img/logo/nongshim.png";
import orchem from "../assets/img/logo/orchem.png";
import samsung_financial from "../assets/img/logo/samsung_financial.png";
import samsung_selc from "../assets/img/logo/samsung_selc.png";
import samyang from "../assets/img/logo/samyang.png";
import sbdc from "../assets/img/logo/sbdc.png";
import SBI from "../assets/img/logo/SBI.png";
import seoul_line from "../assets/img/logo/seoul_line.png";
import sh from "../assets/img/logo/sh.png";
import sh_bank from "../assets/img/logo/sh_bank.png";
import shinhan from "../assets/img/logo/shinhan.png";
import SK01 from "../assets/img/logo/SK01.png";
import SK02 from "../assets/img/logo/SK02.png";
import ssangyong from "../assets/img/logo/ssangyong.png";
import taejeon from "../assets/img/logo/taejeon.png";
import ubcare from "../assets/img/logo/ubcare.png";
import welcome from "../assets/img/logo/welcome.png";
import woori from "../assets/img/logo/woori.png";

const Client = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    speed: 500,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 2500,
    vertical: false,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleSliderClick = () => {
    const externalURL = "https://blog.naver.com/rainbow-brain";
    window.open(externalURL, "_blank");
  };

  return (
    <div className="client_bg">
      <div className="container">
        <div className="client_wrap">
          <p className="button1">
            <img src={hand} className="hand_icon" alt="client img" />
            100+ Clients / 2,000+ Use Cases
          </p>
          <h1 className="heading3">
            다양한 분야의 많은 기업이 레인보우브레인과 함께 하고 있습니다.
          </h1>
          <div onClick={handleSliderClick} className="link"></div>
          <Slider {...settings}>
            <div>
              <img src={ABC} alt="client logo img" />
            </div>
            <div>
              <img src={AIF} alt="client logo img" />
            </div>
            <div>
              <img src={aif02} alt="client logo img" />
            </div>
            <div>
              <img src={amway} alt="client logo img" />
            </div>
            <div>
              <img src={asan} alt="client logo img" />
            </div>
            <div>
              <img src={CJ01} alt="client logo img" />
            </div>
            <div>
              <img src={CJ02} alt="client logo img" />
            </div>
            <div>
              <img src={ctr} alt="client logo img" />
            </div>
            <div>
              <img src={dongwha} alt="client logo img" />
            </div>
            <div>
              <img src={ez} alt="client logo img" />
            </div>
            <div>
              <img src={FINESET} alt="client logo img" />
            </div>
            <div>
              <img src={geps} alt="client logo img" />
            </div>
            <div>
              <img src={han} alt="client logo img" />
            </div>
            <div>
              <img src={harim} alt="client logo img" />
            </div>
            <div>
              <img src={hira} alt="client logo img" />
            </div>
            <div>
              <img src={hmall01} alt="client logo img" />
            </div>
            <div>
              <img src={hmall02} alt="client logo img" />
            </div>
            <div>
              <img src={interlojo} alt="client logo img" />
            </div>
            <div>
              <img src={join} alt="client logo img" />
            </div>
            <div>
              <img src={JVM} alt="client logo img" />
            </div>
            <div>
              <img src={k_export} alt="client logo img" />
            </div>
            <div>
              <img src={k_sure} alt="client logo img" />
            </div>
            <div>
              <img src={KET} alt="client logo img" />
            </div>
            <div>
              <img src={knps} alt="client logo img" />
            </div>
            <div>
              <img src={kosme} alt="client logo img" />
            </div>
            <div>
              <img src={LeeKo} alt="client logo img" />
            </div>
            <div>
              <img src={lotte_Capital} alt="client logo img" />
            </div>
            <div>
              <img src={lotte_chemical} alt="client logo img" />
            </div>
            <div>
              <img src={lotte_fine} alt="client logo img" />
            </div>
            <div>
              <img src={lotte_himart} alt="client logo img" />
            </div>
            <div>
              <img src={megamart} alt="client logo img" />
            </div>
            <div>
              <img src={monalisa} alt="client logo img" />
            </div>
            <div>
              <img src={NDS} alt="client logo img" />
            </div>
            <div>
              <img src={nice} alt="client logo img" />
            </div>
            <div>
              <img src={nice02} alt="client logo img" />
            </div>
            <div>
              <img src={nongshim} alt="client logo img" />
            </div>
            <div>
              <img src={orchem} alt="client logo img" />
            </div>
            <div>
              <img src={samsung_financial} alt="client logo img" />
            </div>
            <div>
              <img src={samsung_selc} alt="client logo img" />
            </div>
            <div>
              <img src={samyang} alt="client logo img" />
            </div>
            <div>
              <img src={sbdc} alt="client logo img" />
            </div>
            <div>
              <img src={SBI} alt="client logo img" />
            </div>
            <div>
              <img src={seoul_line} alt="client logo img" />
            </div>
            <div>
              <img src={sh} alt="client logo img" />
            </div>
            <div>
              <img src={sh_bank} alt="client logo img" />
            </div>
            <div>
              <img src={shinhan} alt="client logo img" />
            </div>
            <div>
              <img src={SK01} alt="client logo img" />
            </div>
            <div>
              <img src={SK02} alt="client logo img" />
            </div>
            <div>
              <img src={ssangyong} alt="client logo img" />
            </div>
            <div>
              <img src={taejeon} alt="client logo img" />
            </div>
            <div>
              <img src={ubcare} alt="client logo img" />
            </div>
            <div>
              <img src={welcome} alt="client logo img" />
            </div>
            <div>
              <img src={woori} alt="client logo img" />
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Client;
