import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getOneConference } from "../api/CenferenceApi";
import ScrollTop from "./ScrollTop";
import { Helmet } from "react-helmet";
import Route from "../components/Route";
import { Link } from "react-router-dom";
import Content from "../components/ContentHeader";

const ConferenceDetail = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  const [conference, setConference] = useState(null);

  // id를 사용하여 데이터를 가져오는 로직을 추가합니다.
  // 예: const data = fetchDataById(id);
  useEffect(() => {
    const fetchConferenceData = async () => {
      try {
        const response = await getOneConference(id); // ID를 전달하여 API 호출
        // console.log("한개검색:: ", response.link);
        setConference(response); // 받아온 데이터를 상태에 저장
      } catch (error) {
        console.error("Error fetching conference data:", error);
      }
    };

    if (id) {
      fetchConferenceData(); // ID가 있을 때 데이터 요청
    }
  }, [id]); // ID가 변경될 때마다 호출

  if (!conference) {
    return <div>Loading...</div>; // 로딩 상태 표시
  }

  return (
    <div className="webinar_wrap">
      <ScrollTop />

      {/* <SideBox /> */}

      {/* 페이지 타이틀명 설정 */}
      <Helmet>
        <title>레인보우브레인 | 지능형자동화 전문기업</title>
      </Helmet>
      <Content
        title="CONFERENCE"
        title1="콘텐츠를 통해 인사이트를 얻어보세요"
      />

      <Route route1="MEDIA" route2="CONFERENCE"></Route>

      <div className="contents_wrap content_bg">
        <div className="container">
          <div className="news_box">
            <div className="news_badge">
              <span>{conference.division}</span>
            </div>

            <div className="news_content">
              <img src={conference.img} alt="이미지" />

              <div className="text_box">
                <span>행사 소개</span>
                <table className="intro">
                  <thead>
                    {conference.title && (
                      <tr>
                        <th>행사명</th>
                        <td style={{ lineHeight: "26px" }}>
                          {conference.title}
                        </td>
                      </tr>
                    )}

                    {conference.content && (
                      <tr>
                        <th>주제</th>
                        <td style={{ lineHeight: "26px" }}>
                          {conference.content}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <th>일시</th>
                      <td>
                        {" "}
                        {(() => {
                          const date = new Date(conference.published_date);
                          const options = {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                            weekday: "long"
                          };
                          const formattedDate = date.toLocaleDateString(
                            "ko-KR",
                            options
                          ); // 'YYYY년 M월 D일 (요일)' 형식
                          // const timeOptions = {
                          //   hour: "numeric",
                          //   minute: "numeric",
                          //   hour12: true
                          // };
                          // const formattedTime = date.toLocaleTimeString(
                          //   "ko-KR",
                          //   timeOptions
                          // ); // '오전 08시' 형식

                          return `${formattedDate} | ${conference.event_time}`;
                        })()}
                      </td>
                    </tr>
                    <tr>
                      <th>비용</th>
                      <td>{conference.fee}</td>
                    </tr>
                    <tr>
                      <th>장소</th>
                      <td>{conference.location}</td>
                    </tr>
                    <tr>
                      <th>채널</th>
                      <td>{conference.platform}</td>
                    </tr>
                  </thead>
                </table>

                <p style={{ paddingTop: "32px" }}>
                  {conference.content_explain}
                </p>
              </div>
            </div>

            <a
              href={conference.link}
              target="_blank"
              rel="noopener noreferrer"
              className="btn_webinar"
            >
              {conference.valuelink === "link" ? "보러가기" : "등록하기"}
            </a>

            <div className="news_btn">
              <Link to="/media/Conference">
                <button className="btn_prev">목록</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConferenceDetail;
