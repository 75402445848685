import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { getPartners } from "../api/api.js";
import ScrollTop from "./ScrollTop";
// import SideBox from "./SideBox.js";
import Content from "../components/ContentHeader.js";
import Route from "../components/Route.js";

// css
import "../css/Partners.css";

function Partners() {
  const [data, setData] = useState([]);
  console.log(data);

  const handleLoad = async () => {
    const result = await getPartners();
    setData(result);
  };

  useEffect(() => {
    handleLoad();
  }, []);
  return (
    <div className="partners_wrap">
      <ScrollTop />

      {/* 페이지 타이틀명 설정 */}
      <Helmet>
        <title>레인보우브레인 | 지능형자동화 전문기업</title>
        <meta
          name="description"
          content="자사의 파트너를 소개하고, 자사 제품의 서비스 소개서를 제공합니다."
        ></meta>
      </Helmet>

      <Content title="PARTNERS"></Content>

      <Route route1="PARTNERS"></Route>
      <div className="content_bg">
        <div className="container">
          <div className="partners_box">
            {data.length > 0 && (
              <div
                className="partners_cont"
                dangerouslySetInnerHTML={{ __html: data[0].content }}
              ></div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Partners;
