import React from "react";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

//img
import main_banner_icon2 from "../assets/img/main_banner_icon2.png";

const handleContactButtonClick = () => {
  scroll.scrollTo(0, {
    //스크롤값
    duration: 100,
    delay: 0,
    smooth: "easeInOutQuart",
    offset: 0,
    top: 0,
  });
};

function NewsLetter() {
  return (
    <div className="side_wrap">
      <Link to="/company/support">
        <div className="newsletter">
          <img src={main_banner_icon2} alt="icon" style={{ width: "37px" }} />
          <p onClick={handleContactButtonClick}>문의하기</p>
        </div>
      </Link>
    </div>
  );
}

export default NewsLetter;
