import { useNavigate } from "react-router-dom";
import { click_count_update } from "../api/Api_newslettermanage";

function formatDate(value) {
  const date = new Date(value);

  if (value) {
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);

    return `${year}년 ${month}월 ${day}일`;
  } else {
    return "";
  }
}

function NewsLetterViewBoard({
  items,
  totalItemsCount,
  currentPage,
  itemsPerPage
}) {
  const navigate = useNavigate();

  const handleClick = (item) => {
    // console.log("넘어온아이템 :: ", item.click_count);
    // navigate(`./viewpage`, { state: { item } });
    // navigate(`./updatepage`, { state: { item } });
    const currentCount = item.click_count ? parseInt(item.click_count, 10) : 0;
    const uploadData = {
      click_count: currentCount + 1
    };
    click_count_update(item.id, uploadData);

    navigate(`./detailpage`, { state: { item } });
  };

  return (
    <table className="newsletter_table">
      <thead>
        <tr>
          <th>Vol</th>
          <th>제목</th>
          <th>발행일</th>
          {/* <th>등록일</th>
                  <th>수정일</th> */}
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) => {
          // const displayIndex = items.length - index;
          const displayIndex =
            totalItemsCount - (currentPage - 1) * itemsPerPage - index;
          return (
            <tr
              key={item.id}
              className={item.top_fixed === 1 ? "highlighted" : ""}
            >
              <th onClick={() => handleClick(item)}>{displayIndex}</th>
              <th onClick={() => handleClick(item)}>{item.title}</th>
              <th onClick={() => handleClick(item)}>
                {formatDate(item.post_date)}
              </th>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default NewsLetterViewBoard;
