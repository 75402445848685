import React from "react";

import BlogBN from "./BlogBN.js";
import NewsLetter from "./NewsLetter";
import ContactBN from "./ContactBN.js";
import ScrollToTopButton from "./ScrollToTopButton";

function SideBox({ onClick }) {
  const handleClick = () => {
    if (typeof onClick === "function") {
      onClick();
    }
  };

  return (
    <div className="side_box contents4">
      <BlogBN />
      <NewsLetter onClick={handleClick} />
      <ContactBN />
      <ScrollToTopButton />
    </div>
  );
}

export default SideBox;
