/* eslint-disable */

import { NavLink } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import Slider from "react-slick";
import buttonArrow from "../assets/img/button_arrow_icon.png";
import mainVisual from "../assets/video/main_visual.mp4";
import mainVisual2 from "../assets/video/main_visual2.mp4";
import upstage from "../assets/img/upstage.png";

// css
import "../css/VisualSlide.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function VisualSlide() {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 4500,
    pauseOnHover: true,
    vertical: false,
  };

  const handleSubscribe3 = () => {
    window.open(
      "https://www.automationanywhere.com/kr/products/automation-360",
      "_blank"
    );
  };

  const handleSubscribe4 = () => {
    window.open("https://www.automationanywhere.com/kr", "_blank");
  };

  const handleContactButtonClick = () => {
    scroll.scrollTo(1740, {
      duration: 100,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: 0,
      top: 0,
    });
  };

  return (
    <div className="visual_container">
      <Slider {...settings}>
        <div className="visual_slide">
          <video loop autoPlay muted playsInline>
            <source src={mainVisual} type="video/mp4" />
          </video>
          <div className="container">
            <div className="visual_text">
              <p className="heading3">
                RAG(검색증강생성) 시스템 기반 지능형 지식베이스
              </p>
              <p className="display1">
                도메인 특화 기업형 AI 플랫폼 구축,
                <br />
                유레카GenAI를 경험해 보세요
              </p>
              <p className="sub_title4">
                빠르고 정확한 LLM 답변, 업무 전문가 수준 파인튜닝 제공!
              </p>
              <div>
                <NavLink
                  to="/business/eurekagenai"
                  className="blue_btn button1"
                >
                  자세히 보기
                  <img src={buttonArrow} alt="회사소개 바로가기" />
                </NavLink>
                <NavLink to="/company/support" className="blue_btn button1">
                  서비스 체험 신청하기​
                  <img src={buttonArrow} alt="회사소개 바로가기" />
                </NavLink>
              </div>
            </div>
          </div>
        </div>

        <div className="visual_slide">
          <video loop autoPlay muted playsInline>
            <source src={mainVisual} type="video/mp4" />
          </video>
          <div className="container">
            <div className="visual_text">
              <p className="heading3">
                여러분의 비즈니스 문제를 LLM Solar로 해결하세요.
              </p>
              <p className="display1">
                강력하고 목적에 맞게 훈련된
                <br />
                LLM Solar
              </p>
              <p className="sub_title4">
                레인보우브레인은 업스테이지의 공식 파트너사입니다.
              </p>
              <div>
                <a
                  href="https://www.upstage.ai/solar-llm?utm_term=About+Solar&utm_content=%2Fhome"
                  className="blue_btn button1"
                  target="blank"
                >
                  LLM Solar 소개 보러가기
                  <img src={buttonArrow} alt="회사소개 바로가기" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="visual_slide">
          <video loop autoPlay muted playsInline>
            <source src={mainVisual2} type="video/mp4" />
          </video>
          <div className="container">
            <div className="visual_text">
              <p className="heading3">전세계 클라우드 RPA 시장점유율 No.1</p>
              <p className="display1">
                Automation Anywhere의
                <br />
                지능형 자동화 플랫폼을 만나 보세요
              </p>
              <p className="sub_title4">
                레인보우브레인은 글로벌 파트너로서 자동화 구축 전과정을
                지원합니다.
              </p>
              <div>
                <button className="blue_btn button1" onClick={handleSubscribe3}>
                  AUTOMATION 360란?
                  <img src={buttonArrow} alt="AUTOMATION 360" />
                </button>
                <button className="blue_btn button1" onClick={handleSubscribe4}>
                  오토메이션애니웨어
                  <img src={buttonArrow} alt="오토메이션애니웨어" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="visual_slide">
          <video loop autoPlay muted playsInline>
            <source src={mainVisual} type="video/mp4" />
          </video>
          <div className="container">
            <div className="visual_text">
              <p className="heading3"></p>
              <p className="display1">
                2024 Automation Anywhere
                <br />
                생성형 AI 웨비나
              </p>
              <p className="sub_title4">
                생성형 AI, 비즈니스를 위한 필수 도구가 되다.
              </p>
              <div>
                <NavLink to="/media/conference" className="blue_btn button1">
                  웨비나 보러가기
                  <img src={buttonArrow} alt="회사소개 바로가기" />
                </NavLink>
              </div>
            </div>
          </div>
        </div>

        <div className="visual_slide">
          <video loop autoPlay muted playsInline>
            <source src={mainVisual} type="video/mp4" />
          </video>
          <div className="container">
            <div className="visual_text">
              <p className="heading3 upstage">
                <img src={upstage} alt="업스테이지 로고" />
              </p>
              <p className="display1">
                레인보우브레인, 업스테이지
                <br />
                인공지능 사업 파트너십 체결​
              </p>
              <p className="sub_title4">
                신속한 디지털 전환 및 스마트한 인공지능 도입을 위한 발걸음!
              </p>
              <div>
                <a
                  href="https://www.etnews.com/20240529000250"
                  className="blue_btn button1"
                  target="blank"
                >
                  소식 더보기
                  <img src={buttonArrow} alt="회사소개 바로가기" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
}

export default VisualSlide;
