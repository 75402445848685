/* eslint-disable */

import { Helmet } from "react-helmet";
import { useLocation, Link } from "react-router-dom";
import Route from "../components/Route.js";
import Content from "../components/ContentHeader.js";
import ScrollTop from "./ScrollTop";

// css
import "../css/newsletter.css";

// img
import buttonArrow from "../assets/img/news_btn_arrow.png";

function NewsLetterViewDetail() {
  const location = useLocation();
  const { item } = location.state;
  // console.log("item :: ", item);

  return (
    <div className="news_detail_wrap">
      <ScrollTop />

      {/* 페이지 타이틀명 설정 */}
      <Helmet>
        <title>레인보우브레인 | 지능형자동화 전문기업</title>
        <meta
          name="description"
          content="AI 소식, 최신 기술 등 관련된 최신 뉴스를 제공합니다."
        ></meta>
      </Helmet>

      <Content
        title="NEWSLETTER"
        title1="(주)레인보우브레인에서는 매월 최신기술동향 및 고객사례와 실무에 도움이 되는 정보를 전해 드리고 있습니다."
      ></Content>

      <Route route1="MEDIA" route2="NEWSLETTER"></Route>
      <div className="content_bg newsletter_detail_wrap">
        <div className="container">
          <h2 className="heading2">{item.title}</h2>
          <div className="partners_box newsletter_detail">
            <iframe itle="NewsLetter" src={item.news_url}></iframe>
            <div className="news_btn d-center">
              {/* <button className="btn_prev contents3">
                <img src={buttonArrow} alt="이전" />
                Prev
              </button> */}
              <Link to="/media/newsletter">
                <button className="btn_list contents3">목록</button>
              </Link>
              {/* <button className="btn_next contents3">
                Next
                <img src={buttonArrow} alt="다음" />
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewsLetterViewDetail;
