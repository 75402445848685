import { Fragment } from "react";
import Main from "./Main";
import ReactDOM from "react-dom/client";

/*
 * 각각의 컴포넌트는 Main.js 에서 호출하도록 설계.
 */

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <Main />
  </>
);
