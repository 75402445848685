import React from "react";
import { Link } from "react-router-dom";
import ScrollTop from "./ScrollTop";

// css
import "../css/App.font.css";
import "../css/AiExpo.css";

// img
import Logo from "../assets/img/logo_white.png";
import arrowBtn from "../assets/img/arrow_icon.png";
import next from "../assets/img/slick_next.png";
import Icon1 from "../assets/img/aiexpo_icon1.png";
import Icon2 from "../assets/img/aiexpo_icon2.png";
import Icon3 from "../assets/img/aiexpo_icon3.png";
import Icon4 from "../assets/img/aiexpo_icon4.png";
import num01 from "../assets/img/num01.png";
import num02 from "../assets/img/num02.png";
import num03 from "../assets/img/num03.png";
import num04 from "../assets/img/num04.png";

function AiExpoChatGPT() {
  const data = [
    {
      image: Icon1,
      title: "기존 문서 기반 주제 선정",
      items: (
        <>
          <p>첨부한 문서에서 ChatGPT가</p>
          <p>적절한 주제를 선정​​</p>
        </>
      ),
    },
    {
      image: Icon2,
      title: "주제에 맞는 썸네일 생성​",
      items: (
        <>
          <p>주제에 맞게 ChatGPT가</p>
          <p>썸네일 이미지 생성​​</p>
        </>
      ),
    },
    {
      image: Icon3,
      title: "주제에 맞는 글 생성​",
      items: (
        <>
          <p>첨부한 문서의 내용을 분석하여</p>
          <p>블로그 글 생성</p>
        </>
      ),
    },
    {
      image: Icon4,
      title: "블로그 완성 및 임시 등록​",
      items: (
        <>
          <p>생성한 중간 결과물을 토대로</p>
          <p>블로그 내용을 작성​</p>
        </>
      ),
    },
  ];

  return (
    <div className="wrap">
      <ScrollTop />

      <div className="header d-flex">
        <div className="expo_container d-between">
          <h1 className="heading1">AI EXPO KOREA 2024</h1>
          <div className="d-flex">
            <img src={Logo} alt="로고 이미지" />
            <p className="contents1">
              <span>&#124;</span>Lead Coexistence of Human and AI in the
              Workflow
            </p>
          </div>
        </div>
      </div>

      <div className="expo_container">
        <div className="main_title d-flex">
          <h1 className="heading2">RPA+ChatGPT 업무 자동화</h1>
          <p className="contents1">
            &#124;&nbsp;&nbsp;업무효율성의 새로운 표준, 더 나은 업무 방식을
            경험하세요.
          </p>
        </div>
        <div className="iframe_wrap">
          <iframe
            width="1024"
            height="576"
            src="https://www.youtube.com/embed/J_Vm66uBxSQ?si=Y4QjIV_bY9tz_XNJ"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>

        <div className="num_wrap">
          {/* <h1 className="heading2">Workflow</h1> */}
          <div className="sub_wrap d-flex">
            {data.map((box, index) => (
              <div
                className="sub_box"
                key={index}
                style={{ width: "260px", margin: "0 30px" }}
              >
                <div className="sub_title4">
                  {box.title.split("\n").map((line, idx) => (
                    <div key={idx}>{line}</div>
                  ))}
                </div>
                <img
                  className="sub_icon"
                  src={box.image}
                  alt={"아이콘 이미지"}
                />
                {index !== data.length - 1 && (
                  <img className="workflow_next" src={next} alt="아이콘" />
                )}
                <div className="contents2">{box.items}</div>
              </div>
            ))}
          </div>
          <img className="num01" src={num01} alt="아이콘" />
          <img className="num02" src={num02} alt="아이콘" />
          <img className="num03" src={num03} alt="아이콘" />
          <img className="num04" src={num04} alt="아이콘" />
        </div>

        <div className="ai_list_btn">
          <Link to="/aiexpo">
            <button className="btn btn_prev d-flex">
              <img className="prev_icon" src={arrowBtn} alt="prev icon" />
              영상 목록으로 돌아가기
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default AiExpoChatGPT;
