import React, { useState } from "react";
import BlogBN from "../pages/BlogBN";
import NewsLetter from "../pages/NewsLetter";
import Modal from "../pages/Modal";

// css
import "../css/Footer.css";

// img
import footerLogo from "../assets/img/footer_logo.png";
// import naverIcon from "../assets/img/naver_blog.png";
import FacebookIcon from "../assets/img/facebook.png";
// import newsIcon from "../assets/img/news_letter.png";
import youtubeIcon from "../assets/img/youtube.png";

function Footer() {
  const [modalOpen, setModalOpen] = useState(false);

  const handleClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="footer_wrap">
      <div className="container">
        <div className="address contents4">
          <img src={footerLogo} alt="rainbowbrain logo" />
          <p>
            주소&nbsp;&#124;&nbsp;서울시 영등포구 은행로 37 기계회관 본관 4층
            (07238)
          </p>
          <p>
            전화&nbsp;&#124;&nbsp;070-4680-6090&nbsp;&#124;&nbsp;팩스&nbsp;&#124;&nbsp;070-4015-3929
          </p>
          <p>
            사업자등록번호&nbsp;&#124;&nbsp;109-86-37604&nbsp;&#124;&nbsp;통신판매업신고&nbsp;&#124;&nbsp;제2021-서울영등포-1620호
          </p>
          <p className="copy">
            Copyright © 2023 RAINBOW BRAIN, All Rights Reserved.
          </p>
        </div>

        <div className="link contents2">
          <div className="footer_blog">
            <BlogBN />
          </div>

          <a
            href="https://www.facebook.com/rbrain.co.kr"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div>
              <img src={FacebookIcon} alt="페이스북" />
            </div>
          </a>

          <div className="footer_news">
            <NewsLetter onClick={handleClick} />
          </div>

          <a
            href="https://www.youtube.com/@rainbowbrain_youtube"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div>
              <img src={youtubeIcon} alt="유튜브" />
            </div>
          </a>
        </div>
      </div>

      {/* 모달 컴포넌트 추가 */}
      {modalOpen && <Modal onClose={handleCloseModal} />}
    </div>
  );
}

export default Footer;
