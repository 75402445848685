import React from "react";
import { Link } from "react-router-dom";
import ScrollTop from "./ScrollTop";

// css
import "../css/App.font.css";
import "../css/AiExpo.css";

// img
import Logo from "../assets/img/logo_white.png";
import arrowBtn from "../assets/img/arrow_icon.png";
import Icon1 from "../assets/img/aiexpo_icon1.png";
import Icon2 from "../assets/img/aiexpo_icon2.png";
import Icon3 from "../assets/img/aiexpo_icon3.png";
import Icon4 from "../assets/img/aiexpo_icon4.png";
import check from "../assets/img/check_icon.png";

function AiExpoECommerce() {
  const data = [
    {
      image: Icon1,
      title: "정보 수집 기준 설정",
      items: (
        <>
          <p>원하는 정보만 수집하도록</p>
          <p>수집 대상 지정​​</p>
        </>
      ),
    },
    {
      image: Icon2,
      title: "데이터 수집 자동화​",
      items: (
        <>
          <p>정기 일정에 맞춰​</p>
          <p>자동으로 수집되는 데이터​</p>
        </>
      ),
    },
    {
      image: Icon3,
      title: "데이터 시각화 제공​",
      items: (
        <>
          <p>가격 추이를 차트로 제공하여</p>
          <p>편안한 분석 기반 마련​</p>
        </>
      ),
    },
    {
      image: Icon4,
      title: "상품 마케팅 전략 수립​",
      items: (
        <>
          <p>데이터 분석에 따른</p>
          <p>상품 마케팅 전략 수립​</p>
        </>
      ),
    },
  ];

  return (
    <div className="wrap">
      <ScrollTop />

      <div className="header d-flex">
        <div className="expo_container d-between">
          <h1 className="heading1">AI EXPO KOREA 2024</h1>
          <div className="d-flex">
            <img src={Logo} alt="로고 이미지" />
            <p className="contents1">
              <span>&#124;</span>Lead Coexistence of Human and AI in the
              Workflow
            </p>
          </div>
        </div>
      </div>

      <div className="expo_container">
        <div className="main_title d-flex">
          <h1 className="heading2">Eureka e-Commerce</h1>
          <p className="contents1">
            &#124;&nbsp;&nbsp;반복 업무를 편안하게 도와주는 이커머스 마케팅
            길잡이, 상품 정보 수집 및 분석 자동화 솔루션
          </p>
        </div>
        <div className="iframe_wrap">
          <iframe
            width="1024"
            height="576"
            src="https://www.youtube.com/embed/hUP_TbgGA2o?si=-FWGfOJImyiaFQMA"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>

        <div>
          {/* <h1 className="heading2">Features</h1> */}
          <div className="sub_wrap d-flex">
            {data.map((box, index) => (
              <div className="sub_box" key={index}>
                <img src={check} className="check_icon" alt="체크 아이콘" />
                <div className="sub_title4">
                  {box.title.split("\n").map((line, idx) => (
                    <div key={idx}>{line}</div>
                  ))}
                </div>
                <img
                  className="sub_icon"
                  src={box.image}
                  alt={"아이콘 이미지"}
                />
                <div className="contents2">{box.items}</div>
              </div>
            ))}
          </div>
        </div>

        <div className="ai_list_btn">
          <Link to="/aiexpo">
            <button className="btn btn_prev d-flex">
              <img className="prev_icon" src={arrowBtn} alt="prev icon" />
              영상 목록으로 돌아가기
            </button>
          </Link>
          <Link to="/aiexpoChatGPT">
            <button className="btn btn_next d-flex">
              RPA+ChatGPT 사례 보러가기
              <img className="next_icon" src={arrowBtn} alt="next icon" />
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default AiExpoECommerce;
