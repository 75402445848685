import home from "../assets/img/home.png";

// -- css -- //
import "../css/Route.css";

function Route(props) {
  return (
    <div className='route contents3'>
      <ul className='container'>
        <li>
          <img src={home} alt='메인으로' /> &nbsp;&nbsp;
        </li>
        <li>{props.route1} &nbsp;</li>
        <li>{props.route2}</li>
      </ul>
    </div>
  );
}

export default Route;
