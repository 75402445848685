import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import ModalInfoProvide from "./ModalInfoProvide";
import { NewsLetterApi } from "../api/NewsLetterApi";

// css
import "../css/Modal.css";

// img
import modalClose from "../assets/img/modal_close.png";

function Modal({ onClose }) {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");

  const handleClick = () => {
    if (typeof onClose === "function") {
      onClose();
    }
  }; //현모달창닫기

  const [isChecked, setIsChecked] = useState(false); //개인정보동의 체크상태
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const modalBackground = useRef(); //배경클릭시 꺼짐

  const [modalOpen, setModalOpen] = useState(false); //정보동의모달상태
  const popupConfirm = () => {
    setModalOpen(true);
  };
  const modalCloseFunc = () => {
    setModalOpen(false);
  };

  const handleRegistration = async () => {
    if (!isChecked) {
      alert("개인정보 수집 및 이용에 동의해주세요.");
      return;
    }

    const options = {
      email: email,
      name: username,
    };
    const registerResult = await NewsLetterApi(options);

    console.log(registerResult);
    onClose();
  };

  return (
    <div className="modal_wrap" ref={modalBackground}>
      <div className="modal_container">
        <button className="modal_close" onClick={handleClick}>
          <img src={modalClose} alt="닫기 버튼" />
        </button>
        <div className="modal_header">
          <h2>Rainbow Brain's</h2>
          <p>Digital Expert Newsletter</p>
        </div>

        <div className="modal_box">
          <div className="modal_title">
            <h1>📩 RPA+AI 뉴스레터 구독하기</h1>
            <p style={{ paddingBottom: "32px" }}>
              지능형자동화 전문기업 레인보우브레인이
              <br />
              생생한 자동화 성공사례와 AI 업계 소식을 월 1회 📧전해드립니다.
            </p>
          </div>
          <p>
            이름 <span>*</span>
          </p>
          <input
            type="text"
            placeholder="이름"
            value={username}
            onChange={handleUsernameChange}
          />
          <p>
            이메일 주소(회사 메일) <span>*</span>
          </p>
          <input
            type="email"
            value={email}
            placeholder="이메일 주소"
            onChange={handleEmailChange}
          />
          {/* checkbox */}
          <div className="d-flex">
            <input
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            <p onClick={popupConfirm}>
              (필수) 개인정보 수집 및 이용 에 동의합니다.
            </p>
            {modalOpen && <ModalInfoProvide onClose={modalCloseFunc} />}
          </div>

          <button className="sub_btn" onClick={handleRegistration}>
            구독하기
          </button>
          <Link
            to="/media/newsletter"
            className="sub_btn_news"
            onClick={handleClick}
          >
            지난 뉴스레터 보러가기
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Modal;
