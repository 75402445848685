import naverIcon from "../assets/img/naver_blog.png";

function NewsLetter() {
  return (
    <div className="side_wrap">
      <a
        href="https://blog.naver.com/rainbow-brain"
        target="_blank"
        rel="noopener noreferrer"
        className="newsletter"
      >
        <img src={naverIcon} alt="블로그" />
        블로그
      </a>
    </div>
  );
}

export default NewsLetter;
