import React from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { getAuthToken, getAnswer } from "../api/api.js";
import ScrollTop from "./ScrollTop";
import ScrollToTopButton from "./ScrollToTopButton";

// css
import "../css/RpaPage.css";
import "../css/SupportPage.css";

// img
import Content from "../components/ContentHeader";
import logoImg from "../assets/img/logo_white.png";
import loadingImg from "../assets/img/loading.png";
import newsLetterImg from "../assets/img/news_letter.png";

function ExpoPage() {
  // 공무원규정
  const btnAnswer = [
    "코로나 백신을 맞으려고하는데 공가 사용 가능한가요?",
    "병가는 법적으로 며칠까지 사용이 가능한가요?",
    "공무원은 자유복장으로 출근이 가능한가요?",
    "불가피하게 주말에 근무를 하게 되면 수당을 받을 수 있나요?",
    "임신중인 공무원은 육아휴직을 얼마나 사용할 수 있나요?",
  ];

  // 금융규정
  const btnAnswer_2 = [
    "해당 보험사랑 분쟁이 생겼는데 어떻게 해야 하나요?",
    "보험금이 지급되지 않는 조건이 뭐가 있나요?",
    "ADHD로 보험금을 받으려고 하는데 어떤 걸 확인해야 하나요?",
    "교통사고를 당했을 때 입원하면 보험금 지급을 어떻게 할 수 있나요?",
    "식중독으로 입원했는데 보험 청구를 어떻게 하나요?",
  ];

  const [selectOption, setSelectOption] = useState("금융"); // input 1
  const [input1, setInput1] = useState(""); // input 1
  const [output1, setOutput1] = useState("답변이 표시됩니다."); // output 1 : 답변
  const [output2, setOutput2] = useState("참고내용이 표시됩니다."); // output 2 : 참고자료

  const [submitBtnState, setSubmitBtnState] = useState(true);
  const [loading, setLoading] = useState(false);
  // console.log(submitBtnState);

  const handleInputChange1 = (e) => {
    setInput1(e.target.value);
  };

  const handleSelectChange = (e) => {
    // console.log(e.target.value);

    setSelectOption(e.target.value);
  };

  const handleOnClickBtn = (e) => {
    // console.log(e.target.textContent);
    setInput1(e.target.textContent);
  };

  async function handleSubmit(e) {
    e.preventDefault();

    setSubmitBtnState(false);
    setLoading(true);

    console.log("RPA 실행");
    console.log(input1);
    const now = new Date();
    const timeString =
      "[" +
      now.getHours().toString().padStart(2, "0") +
      ":" +
      now.getMinutes().toString().padStart(2, "0") +
      ":" +
      now.getSeconds().toString().padStart(2, "0") +
      "]";

    const inputData = {
      input1: timeString + input1 + `[${selectOption}]`,
    };

    getAuthToken(inputData); // RPA를 실행하는 함수.

    // ▼ 실행하고나서 디비에 답변이 들어갈때까지 기다리는 작업.
    let intervalId;

    const timeoutId = setTimeout(() => {
      clearInterval(intervalId);
      console.log("getAnswer 함수 종료");
      setOutput1("값이 없습니다.");
      setSubmitBtnState(true);
      setLoading(false);
    }, 180000); // 3분 동안 반복

    intervalId = setInterval(async () => {
      const body = await getAnswer(inputData);
      if (body.length > 0) {
        const answer = body[0].answer;
        const reference = body[0].reference;
        console.log(answer);
        if (answer) {
          // answer값이 널값이 아니면 함수를 중지시킴.
          clearInterval(intervalId);
          clearTimeout(timeoutId);
          console.log("getAnswer 함수 종료");
          setOutput1(answer);
          setOutput2(reference);
          setSubmitBtnState(true);
          setLoading(false);
        }
      }
    }, 1000); // 1초마다 실행
  }

  return (
    <div className="rpa_page expo_page expo_faq">
      <ScrollTop />

      {/* 페이지 타이틀명 설정 */}
      <Helmet>
        <title>레인보우브레인 | 지능형자동화 전문기업</title>
      </Helmet>

      {loading && (
        <div>
          <div className="bg_loading"></div>
          <div className="loading_wrap">
            <img src={loadingImg} alt="loading" />
            <div className="loading">
              <span></span>
              <span></span>
              <span></span>
            </div>
            <p className="heading2">
              옆 모니터에서
              <br />
              <span>진행사항</span>을 확인 해 주세요.
            </p>
          </div>
        </div>
      )}

      <div className="expo_header">
        <a href="/" className="mobile_logo">
          <img src={logoImg} alt="로고 이미지" />
        </a>
      </div>

      <Content title="RPA 솔루션과 ChatGPT를 활용한 업무자동화 시연"></Content>
      <div className="side_box">
        <div className="newsLetter">
          <a
            href="https://page.stibee.com/subscriptions/146545"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="newsLetter_wrap">
              <img src={newsLetterImg} alt="newsLetter" />
              <p className="sub_title4">NewsLetter</p>
            </div>
          </a>
        </div>
        <ScrollToTopButton />
      </div>

      <div className="content_bg">
        <div>
          <div className="container">
            <div className="support_header">
              <h2 className="heading1">규정 FAQ</h2>
            </div>

            <div className="faq_search expo_search">
              <form onSubmit={handleSubmit}>
                <select
                  className="selectbox"
                  name="select1"
                  onChange={handleSelectChange}
                >
                  <option value="금융">금융</option>
                  <option value="공무원규정">공무원규정</option>
                </select>
                <input
                  className="contents2"
                  name="input1"
                  value={input1}
                  placeholder="#{주제}에 대해 알아보고 싶은 내용을 입력하여 문의해보세요."
                  onChange={handleInputChange1}
                ></input>
                <button
                  className="blue_btn button2"
                  type="submit"
                  disabled={!submitBtnState}
                >
                  문의하기
                </button>
              </form>
            </div>

            {/* 자주하는질문 버튼 */}
            <div className="faq_list expo_content">
              <div style={{ display: "flex" }}>
                <p className="sub_title4 expo_p">예시 질문</p>
                <div className="expo_border">
                  {(selectOption === "금융" ? btnAnswer_2 : btnAnswer).map(
                    (data, index) => (
                      <button
                        className="contents2 expo_badge"
                        key={index}
                        onClick={handleOnClickBtn}
                      >
                        {data}
                      </button>
                    )
                  )}
                </div>
              </div>
            </div>

            <div className="faq_list">
              <div className="expo_content">
                <p className="sub_title4 expo_p">답변 내용</p>
                <p className="contents1 expo_border">{output1}</p>
              </div>
              <div className="expo_content expo1">
                <p className="sub_title4 expo_p">참고 내용</p>
                <p className="contents1 expo_border">{output2}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExpoPage;
