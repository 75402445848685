import React from "react";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

// css
import "../css/Slogan.css";

// img
import main_banner_icon1 from "../assets/img/main_banner_icon1.png";
import main_banner_icon2 from "../assets/img/main_banner_icon2.png";
import main_banner_icon3 from "../assets/img/main_banner_icon3.png";
import main_banner_icon4 from "../assets/img/main_banner_icon4.png";
import main_banner_icon5 from "../assets/img/main_banner_icon5.png";
import main_banner_icon6 from "../assets/img/main_banner_icon6.png";
import buttonArrow from "../assets/img/button_arrow_icon.png";

const handleContactButtonClick = () => {
  scroll.scrollTo(0, {
    duration: 100,
    delay: 0,
    smooth: "easeInOutQuart",
    offset: 0,
    top: 0,
  });
};

function Slogan() {
  return (
    <div className="slogan_wrap">
      <div className="container">
        <div>
          <img
            src={main_banner_icon1}
            alt="banner icon"
            className="main_banner_icon1"
          />
          <img
            src={main_banner_icon2}
            alt="banner icon"
            className="main_banner_icon2"
          />
          <img
            src={main_banner_icon3}
            alt="banner icon"
            className="main_banner_icon3"
          />
          <img
            src={main_banner_icon4}
            alt="banner icon"
            className="main_banner_icon4"
          />
          <img
            src={main_banner_icon5}
            alt="banner icon"
            className="main_banner_icon5"
          />
          <img
            src={main_banner_icon6}
            alt="banner icon"
            className="main_banner_icon6"
          />
        </div>

        <div>
          <p className="heading3">인간과 로봇의 연계</p>
          <h1 className="display2">
            Lead Coexistence of Human and AI in the Workflow​
          </h1>
          <p className="sub_title4">
            지능형자동화 전문기업 RAINBOW BRAIN과 함께 디지털 전환의 발판을
            마련해 보세요.
          </p>

          <div className="slogan_link">
            <Link to="/company/support">
              <button
                className="blue_btn button1"
                onClick={handleContactButtonClick}
              >
                CONTACT
                <img src={buttonArrow} alt="CONTACT" />
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Slogan;
