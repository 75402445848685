import React from "react";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getNews } from "../api/api.js";
import ScrollTop from "./ScrollTop";
// import SideBox from "./SideBox.js";
import Content from "../components/ContentHeader";
import Route from "../components/Route";

// css
import "../css/News.css";
import "../css/NewsPage.css";

// img
import main_thumb1 from "../assets/img/no_image.png";
import m_select_icon from "../assets/img/mobile_select_arrow.png";
import { news_click } from "../api/Api_newslettermanage.js";

function NewsPage() {
  // const tab = ["ALL", "RAINBOW BRAIN", "RPA", "AUTOMATION ANYWHERE", "NOTICE"];
  const tab = ["ALL", "RAINBOW BRAIN", "AUTOMATION ANYWHERE"];

  const [data, setData] = useState([]);
  const [renderData, setRenderData] = useState([...data]);
  const [activeIndex, setActiveIndex] = useState(0); // 선택된 li의 인덱스
  const [limit, setLimit] = useState(8);

  const navigate = useNavigate();

  const slicedList = (data) => {
    const isMobile = window.innerWidth < 600;
    return data?.slice(0, isMobile ? limit : limit);
  };

  const handleClick = (e) => {
    const index = tab.indexOf(e.target.textContent.toUpperCase()); // 선택된 li의 인덱스 계산
    if (index === -1) return; // tab 배열에 없는 값이면 무시
    setActiveIndex(index); // 선택된 li의 인덱스 저장
    if (e.target.textContent.toLowerCase() === "all") {
      setRenderData(data);
    } else {
      const filter = [...data].filter((item) =>
        item.division.includes(e.target.textContent)
      );
      setRenderData(filter);
    }
  };

  /* ─────────────────────────────────────────────────────
   * handleClick : 뉴스 카드 클릭하면 실행되는 함수.
   * ─────────────────────────────────────────────────────
   */
  const handleClickDetail = (item, index) => {
    // console.log("NewsPage :: ", item);
    const currentCount = item.click_count ? parseInt(item.click_count, 10) : 0;
    const uploadData = {
      click_count: currentCount + 1
    };
    news_click(item.id, uploadData);

    // navigate(`./newsDetail`, { state: { item } });
    // console.log(item);

    navigate(`./detail?id=${item.id}`, {
      state: { index, renderData } // 이전다음버튼때문에 통째로넘김
    });
  };

  // 더보기 버튼 누르면 리스트가 4개씩 더 나옴.
  const handleMorebuttonClick = (e) => {
    setLimit(limit + 4);
  };

  const handleLoad = async () => {
    const news = await getNews();
    setData(news);
    setRenderData(news);
  };

  useEffect(() => {
    handleLoad();
  }, []);

  let [toggle, setToggle] = useState(false);

  // console.log("렌더데이터:: ", renderData);

  return (
    <div className="news_detail_wrap">
      <ScrollTop />

      {/* 페이지 타이틀명 설정 */}
      <Helmet>
        <title>레인보우브레인 | 지능형자동화 전문기업</title>
        <meta
          name="description"
          content="AI 소식, 최신 기술 등 관련된 최신 뉴스를 제공합니다."
        ></meta>
      </Helmet>

      <Content
        title="NEWS"
        title1="레인보우브레인의 새로운 소식을 전해드려요"
      ></Content>

      <Route route1="MEDIA" route2="NEWS"></Route>

      <div className="content_bg">
        <div className="container">
          <div className="news_wrap news_wrap_top">
            <div className="title">
              <ul className="contents1 news_list news_list_flex">
                {tab.map((item, index) => {
                  const active = index === activeIndex ? "news_active" : "";
                  // const last = index === 4 ? "last" : "";
                  const last = index === 3 ? "last" : "";
                  return (
                    <li
                      key={index}
                      onClick={handleClick}
                      className={`${last} ${active}`}
                    >
                      <span>{item}</span>
                    </li>
                  );
                })}
              </ul>

              <div className="m_news_list_wrap">
                <button
                  className="m_news_select"
                  onClick={() => {
                    setToggle(!toggle);
                  }}
                >
                  <p className="sub_title4">ALL</p>
                  <img
                    className="m_select_icon"
                    src={m_select_icon}
                    alt="m_select_icon"
                  />
                </button>
                {toggle === true ? (
                  <ul className="contents1 m_news_list">
                    {tab.map((item, index) => {
                      const first = index === 0 ? "first" : "";
                      return (
                        <li key={index} onClick={handleClick} className={first}>
                          <span>{item}</span>
                        </li>
                      );
                    })}
                  </ul>
                ) : null}
              </div>
            </div>

            <div className="news_wrap_item">
              {/* 최상단 첫번째 뉴스 */}
              {renderData.slice(0, 1).map((item) => (
                <div
                  className="news_first news"
                  key={item.id}
                  onClick={() => handleClickDetail(item)}
                >
                  <div className="news_img">
                    <img
                      src={item.fileUrl ? item.fileUrl : main_thumb1}
                      alt="main_thum"
                    />
                  </div>
                  <div className="news_thumbnail">
                    <div className="news_badge contents3">
                      <span>{item.division}</span>
                    </div>
                    <p className="sub_title4 news_title">{item.title}</p>
                    <p
                      className="contents2 news_content"
                      dangerouslySetInnerHTML={{
                        __html:
                          item.summary_content ||
                          item.content
                            .replace(/&nbsp;/g, "<br>")
                            .replace(/<figure[^>]*>.*<\/figure>/g, "")
                            .replace(/<img[^>]*>/g, "")
                      }}
                    ></p>
                  </div>
                </div>
              ))}

              {/* 뉴스 리스트 */}
              {slicedList(renderData).map((item, index) => (
                <div
                  key={item.id}
                  onClick={() => handleClickDetail(item, index)}
                  style={{ display: "inline" }}
                >
                  <Card
                    image={item.fileUrl ? item.fileUrl : main_thumb1}
                    key={item.id}
                    category={item.division}
                    title={item.title}
                    content={item.content}
                    summary_content={item.summary_content}
                  ></Card>
                </div>
              ))}
            </div>
          </div>

          <div className="contact_footer">
            <button
              type="button"
              className="blue_btn button1"
              onClick={handleMorebuttonClick}
            >
              더보기
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function Card(props) {
  return (
    <div className="news">
      <div className="news_img">
        <img src={props.image} alt="main_thum" />
      </div>
      <div className="news_thumbnail">
        <div className="news_badge contents3">
          <span>{props.category}</span>
        </div>
        <p className="sub_title4 news_title">{props.title}</p>

        {/* 요약내용이 있으면 요약내용 우선 출력 */}
        <p
          className="contents2 news_content"
          dangerouslySetInnerHTML={{
            __html:
              props.summary_content ||
              props.content
                .replace(/&nbsp;/g, "<br>")
                .replace(/<figure[^>]*>.*<\/figure>/g, "")
                .replace(/<img[^>]*>/g, "")
          }}
        ></p>
      </div>
    </div>
  );
}

export default NewsPage;
