import React, { useEffect } from "react";

// -- css -- //
import "../css/Kakao.css";

const { kakao } = window;

function Kakao() {
  useEffect(() => {
    const container = document.getElementById("map");
    const options = {
      center: new kakao.maps.LatLng(37.52946109483467, 126.92254841129082),
      level: 3,
    };
    const map = new kakao.maps.Map(container, options);
    var markerPosition = new kakao.maps.LatLng(
      37.52946109483467,
      126.92254841129082
    );
    var marker = new kakao.maps.Marker({
      position: markerPosition,
    });
    marker.setMap(map);
  }, []);

  return <div id="map"></div>;
}

export default Kakao;
