import React from "react";
import { NavLink } from "react-router-dom";

// css
import "../css/MainBanner.css";

// img
import searchIcon from "../assets/img/icon_search.png";
import settingIcon from "../assets/img/icon_setting.png";
import arrowBtn from "../assets/img/button_arrow_icon.png";

function MainBanner() {
  const bannerItems = [
    {
      link: "/company/introduction",
      text: "RAINBOW BRAIN을 더 알고 싶다면?",
      imgSrc: searchIcon,
      btnText: "회사 소개",
    },
    {
      link: "/business/rpa",
      text: "RPA에 대해 더 많이 알고 싶다면?",
      imgSrc: settingIcon,
      btnText: "RPA 소개",
    },
    // {
    //   link: "/expo/rpa",
    //   text: "엑스포",
    //   imgSrc: settingIcon,
    //   btnText: "엑스포 시연",
    // },
    // { text: "텍스트 추가", imgSrc: searchIcon, btnText: "버튼 텍스트 추가" },
  ];

  return (
    <div className="banner_wrap">
      {bannerItems.map((item, i) => (
        <div className="banner_cont container" key={i}>
          <div>
            <img src={item.imgSrc} alt="banner icon" />
            <p className="contents1">{item.text}</p>
          </div>

          <NavLink to={item.link}>
            <button className="blue_btn1 button2">
              {item.btnText} 보러 가기
              <img src={arrowBtn} alt="arrow icon" />
            </button>
          </NavLink>
        </div>
      ))}
    </div>
  );
}

export default MainBanner;
