/* eslint-disable */

import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollTop from "./ScrollTop.js";
import SideBox from "./SideBox.js";
import Content from "../components/ContentHeader.js";
import Route from "../components/Route.js";
import { CopyToClipboard } from "react-copy-to-clipboard/src";
import { getAllConference } from "../api/CenferenceApi.js";

// css
import "../css/News.css";
import "../css/NewsPage.css";
import "../css/Conference.css";

// img
import URL from "../assets/img/link.png";
import Icon1 from "../assets/img/webinar_icon1.png";
import Icon2 from "../assets/img/webinar_icon2.png";
import Icon3 from "../assets/img/webinar_icon3.png";
import Icon4 from "../assets/img/webinar_icon4.png";
import Icon5 from "../assets/img/webinar_icon5.png";

import NoImage from "../assets/img/no_image.png";
// import Cont01 from "../assets/img/web_cont01.png";
// import Cont02 from "../assets/img/web_cont02.png";
// import Cont03 from "../assets/img/web_cont03.png";
// import Cont04 from "../assets/img/web_cont04.png";
// import Cont05 from "../assets/img/web_cont05.png";
// import Cont06 from "../assets/img/web_cont06.png";
// import Cont07 from "../assets/img/web_cont07.png";
// import Cont08 from "../assets/img/web_cont08.png";
// import Cont09 from "../assets/img/web_cont09.jpg";

import Logo from "../assets/img/logo_dark.png";
import aaLogo from "../assets/img/aa_logo.png";

const tabs = [
  { label: "ALL", filter: null },
  { label: "ONLINE", filter: "ONLINE" },
  { label: "OFFLINE", filter: "OFFLINE" },
  {
    label: "2024 Automation Anywhere 생성형 AI 웨비나",
    filter: "2024 Automation Anywhere 생성형 AI 웨비나"
  }
];

// const sampleData = [
//   {
//     id: 1,
//     division: "OFFLINE",
//     image: Cont01,
//     published_date: "2024년 1월 25일(목) | 오후 03:00~08:00",
//     title: "2024 Automation Anywhere 고객 세미나",
//     content: "“Embrace the Power of RPA and Generative AI”",
//     link: "http://www.rbrain.co.kr/media/contents01"
//   },
//   {
//     id: 2,
//     division: "ONLINE",
//     image: Cont02,
//     published_date: "2024년 4월 26일(금) | 오전 10:00~11:00",
//     title: "생성형 AI 도입을 위한 기업 나침반​",
//     content:
//       "생성형 AI 기술을 둘러싼 생태계의 구성 요소와 고려 사항을 살펴보고, 생성형 AI 모델을 선택할 때, 생성형 AI 개발 환경을 선택할 때, 생성형 AI 인프라를 선택할 때 고려 사항을 구체적인 사례로 정리한다. 상용 소프트웨어/모델과 오픈 소스 소프트웨어/모델이 계속해서 발전하는 상황이므로, ​지속적으로 기술 생태계에 주목하고 핵심 기술을 선택해서 발전시켜 나가야 치열한 경쟁 구도에서 낙오자가 되지 않을 것이다.",
//     link: "http://www.rbrain.co.kr/media/contents02"
//   },
//   {
//     id: 3,
//     division: "ONLINE",
//     image: Cont03,
//     published_date: "2024년 5월 29일(수) | 오후 02:00~03:00",
//     title: "생성형 AI 랜드스케이프 확장",
//     content:
//       "챗GPT 뿐만 아니라 다양한 모달리티에서 생성형 AI가 적극적으로 활용되는 한 해가 될 것으로 예상한다. 텍스트는 물론이고 이미지, 동영상, 오디오로 확장되고, 이를 통합하는 멀티 모달 생성형 AI가 등장하면서 LLM에서 L(Language)를 대신하는 다른 용어를 찾으려는 움직임도 보이고 있다.",
//     link: "http://www.rbrain.co.kr/media/contents03"
//   },
//   {
//     id: 9,
//     division: "OFFLINE",
//     image: Cont09,
//     published_date: "2024년 6월 19일 | 오후 01:00~06:00",
//     title: "코오롱베니트 AI Alliance: CONNECT 2024",
//     content: "검색증강생성(RAG) 주요 기술 및 유레카RAG 금융권 사례 소개",
//     link: "http://www.rbrain.co.kr/media/contents09"
//   },
//   {
//     id: 4,
//     division: "ONLINE",
//     image: Cont04,
//     published_date: "2024년 6월 26일(수) | 오후 02:00~03:00",
//     title: "엣지향 LLM의 등장​",
//     content:
//       "최근 스마트폰에서도 LLM이 동작하기 시작했고, 노트북과 패드까지 AI 기능이 탑재되기 시작했다. 엣지향 LLM이 관심을 끌게된 배경과 업무에 적합한 엣지향 LLM의 사용 사례를 살펴본 다음에 GPU, CPU, NPU을 중심으로 어떤 하드웨어를 선택해야 하는지 일목요연하게 정리한다.",
//     link: "http://www.rbrain.co.kr/media/contents04"
//   },
//   {
//     id: 5,
//     division: "ONLINE",
//     image: Cont05,
//     published_date: "2024년 7월 31일(수) | 오후 02:00~03:00",
//     title: "에이전트와 멀티 모달로 강화되는 RAG",
//     content:
//       "LLM의 환각을 줄이고 기업내 지식 기반을 축적해서 정확한 정보를 전달하기 위해 고안된 RAG는 이제 에이전트와 결합해 외부 정보를 유연하게 통합하고 멀티 모달 기능으로 텍스트 뿐만 아니라 이미지까지 확장될 것으로 예상한다. 기존 기업 내 지식을 관리하는 인프라인 DMS(Document Management System)와 KMS(Knowledge Management System)와 비교해 어떤 특장점이 있는지 일목요연하게 정리한다.",
//     link: "http://www.rbrain.co.kr/media/contents05"
//   },
//   {
//     id: 6,
//     division: "ONLINE",
//     image: Cont06,
//     published_date: "2024년 8월 28일(수) | 오후 02:00~03:00",
//     title: "빅데이터를 넘어선 데이터 중심의 프로세스 정립​",
//     content:
//       "기업 내에서 LLM을 구축하거나 RAG를 구축하는 과정에서 어떤 데이터를 수집해 어떤 방식으로 가공할지가 무척 중요하다. 빅데이터 시절에 얻은 교훈을 토대로 기업 내부와 기업 외부의 데이터 뿐만이 아니라 합성 데이터를 활용하는 방식의 장단점을 일목요연하게 정리한다.",
//     link: "http://www.rbrain.co.kr/media/contents06"
//   },
//   {
//     id: 7,
//     division: "ONLINE",
//     image: Cont07,
//     published_date: "2024년 9월 25일(수) | 오후 02:00~03:30",
//     title: "LLM과 결합한 클라우드의 약진​",
//     content:
//       "앞서 엣지향 LLM의 반대쪽에는 클라우드향 LLM이 존재한다. 기업에서 직접 프라이빗 클라우드에 구축할 수도 있고, CSP가 제공하는 LLM 서비스를 사용할 수도 있고, 전문 LLM 기업이 제공하는 LLM 서비스를 사용할 수도 있다. 이렇게 클라우드에서 LLM을 운용하기 위한 의사 결정 과정에서 필요한 항목을 일목 요연하게 정리한다.",
//     link: "http://www.rbrain.co.kr/media/contents07"
//   },
//   {
//     id: 8,
//     division: "ONLINE",
//     image: Cont08,
//     published_date: "2024년 10월",
//     title: "기업의 적극적인 LLM 대비​",
//     content:
//       "이제 LLM은 선택이 아니라 필수로 다가오고 있다. 데이터 거버넌스, 보안과 저작권 문제, 인프라 확충, 기존 시스템/서비스와 연계하거나 신규 시스템/서비스를 개발을 포함해 업무 효율성을 위해 LLM을 도입하는 과정에서 대비해야 하는 사안을 총정리한다.",
//     link: "http://www.rbrain.co.kr/media/contents08"
//   }
// ];

function Conference() {
  const [selectedTab, setSelectedTab] = useState(tabs[3].filter);
  const [sampleData, setSampleData] = useState([]);

  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=AW-10966372634";
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      window.gtag = gtag;
      gtag("js", new Date());
      gtag("config", "AW-10966372634");

      gtag("event", "conversion", {
        send_to: "AW-10966372634/PojcCL3Py9wDEJqilu0o"
      });
    };

    return () => {
      if (script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  }, []);

  const handleTabClick = (tabLabel) => {
    setSelectedTab(tabLabel);
  };

  const filterDataByTab = () => {
    if (selectedTab === "ALL") {
      return sampleData;
    } else if (selectedTab === "2024 Automation Anywhere 생성형 AI 웨비나") {
      return sampleData.filter(
        (item) => item.division === "ONLINE" || item.division === selectedTab
      );
    } else {
      return sampleData.filter((item) => item.division === selectedTab);
    }
  };

  // const handleCopyClipBoard = (text) => {
  //   try {
  //     navigator.clipboard.writeText(text);
  //     alert("클립보드에 복사되었습니다.");
  //   } catch (error) {
  //     alert("클립보드 복사에 실패하였습니다.");
  //   }
  // };

  const alldata = async () => {
    try {
      const result = await getAllConference();
      // console.log("불러온 데이터:: ", result);
      setSampleData(result);
    } catch (error) {
      console.error("API 호출에 실패했습니다:", error);
    }
  };
  useEffect(() => {
    alldata();
  }, []);

  return (
    <div className="webinar_wrap">
      <ScrollTop />
      <SideBox />

      <Helmet>
        <title>레인보우브레인 | 지능형자동화 전문기업</title>
        <meta
          name="description"
          content="비즈니스를 위한 생성형 AI에 대한 다양한 정보를 제공합니다."
        ></meta>
      </Helmet>

      <Content
        title="CONFERENCE"
        title1="콘텐츠를 통해 인사이트를 얻어보세요"
      />
      {/* <Content
        title="RPAI e-Learning 스쿨"
        title1="레인보우브레인이 선보이는 RPA 월간 Biz / Tech Trend 세션"
      /> */}

      <Route route1="MEDIA" route2="CONFERENCE" />

      <div className="content_bg">
        <div className="container">
          <div className="news_wrap news_wrap_top">
            <div className="title">
              <ul className="contents1 news_list news_list_flex">
                {tabs.map((tab, index) => (
                  <li
                    key={index}
                    onClick={() => handleTabClick(tab.label)}
                    className={selectedTab === tab.label ? "news_active" : ""}
                  >
                    <span>{tab.label}</span>
                  </li>
                ))}
              </ul>
            </div>

            <div className="news_wrap_item">
              {selectedTab !== "OFFLINE" && (
                <div className="conference_box">
                  <div className="cont1">
                    <h1>2024 Automation Anywhere 생성형 AI 웨비나</h1>
                    <h3>생성형 AI, 비즈니스를 위한 필수 도구가 되다.</h3>
                    <CopyToClipboard
                      text="http://www.rbrain.co.kr/media/conference"
                      onCopy={() => alert("클립보드에 복사되었습니다.")}
                    >
                      <button className="url_link">
                        <img src={URL} alt="이미지" />
                        링크 복사하기
                      </button>
                    </CopyToClipboard>

                    <div className="logo_wrap">
                      <img src={Logo} alt="이미지" />
                      <img src={aaLogo} alt="이미지" />
                    </div>
                  </div>

                  {/* 
                  <div className="title_box">
                    <h2>CONTENT</h2>
                    <p>콘텐츠를 통해 다양한 정보를 획득하세요</p>
                  </div> */}

                  <div className="cont2">
                    <p className="title">웨비나 소개</p>
                    <div className="iframe" style={{ marginTop: "30px" }}>
                      <iframe
                        src="https://www.youtube.com/embed/1JhkETMTuYY?si=nhm-ic2yNhTwK3Wq"
                        title="YouTube video player"
                        frameBorder="0" // 카멜 케이스로 수정
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin" // 카멜 케이스로 수정
                        allowFullScreen // 카멜 케이스로 수정
                      ></iframe>
                    </div>
                    <p className="mt_minus">
                      2022년 말에 챗GPT가 등장하면서 2023년 한 해 동안 AI 기술의
                      지형도가 크게 바뀌었다. 비즈니스 관점에서 보면, 기존의
                      규칙 기반이나 딥러닝 기반의 인공지능은 데이터를 분석해서
                      원인을 찾거나 미래를 예측하는 등 의사 결정 과정에서 통찰을
                      제공하기 위한 목적이 컸지만, 챗GPT로 대표되는 생성형 AI는
                      사람의 입력을 받아서 의도에 맞는 결과를 출력한다는 점에서
                      우리 모두에게 강한 충격을 줬다.
                      <br />
                      <br />
                      하지만 단일한 벤더 중심의 상자만 열면 바로 나오는 기성품이
                      아니라는 사실이 무척 중요하다. 상용 서비스는 물론이고 오픈
                      소스를 등에 업은 오픈 모델까지 매주 새로운 기술이 쏟아져
                      나오고, 데이터센터에서 동작하는 생성형 AI 워크로드는
                      하드웨어 발전에 따라 점차 스마트폰을 비롯한 엣지 단으로
                      내려오고 있다. 이처럼 하루가 다르게 발전하는 기술 환경에서
                      경쟁에 뒤쳐지지 않으려면 기업이 확보하고 있는 데이터와
                      컴퓨팅 자원을 최대로 활용해 기존 서비스나 제품과
                      연계하거나 신규 서비스나 제품을 만들어내야만 한다.
                      아날로그에서 디지털로 전환하는 만큼이나 중요한 변화가
                      한꺼번에 그것도 매우 빠르게 일어나고 있다.
                      <br />
                      <br />
                      기업들이 자동화를 넘어서 초지능화로 접근할 때 생성형 AI는
                      빼놓을 수 없는 핵심 기술로 자리잡고 있기에 생성형 AI를
                      둘러싼 생태계를 제대로 이해할 필요성이 생겼고, 이를 위해
                      이번 세미나에서는 2024년에 일어날 변화를 생성형 AI
                      관점에서 조감하기로 한다.
                    </p>

                    <p className="title" style={{ marginTop: "70px" }}>
                      웨비나 개요
                    </p>
                    <div className="d-flex">
                      <div className="d-column">
                        <img src={Icon1} alt="이미지" />
                        <p>
                          2024년
                          <br />
                          4월~10월까지
                          <br />
                          (월 1회)
                        </p>
                      </div>
                      <div className="d-column">
                        <img src={Icon2} alt="이미지" />
                        <p>
                          온라인
                          <br />
                          (YouTube)
                        </p>
                      </div>
                      <div className="d-column">
                        <img src={Icon3} alt="이미지" />
                        <p>
                          비즈니스 자동화가
                          <br />
                          필요한 기업 및<br />
                          기관 종사자
                        </p>
                      </div>
                      <div className="d-column">
                        <img src={Icon4} alt="이미지" />
                        <p>무료</p>
                      </div>
                      <div className="d-column">
                        <img src={Icon5} alt="이미지" />
                        <p>
                          레인보우브레인
                          <br />
                          홈페이지
                          <br />
                          (각 콘텐츠)
                        </p>
                      </div>
                    </div>
                    <p className="d-end">
                      * 발표주제 및 일정은 상황에 따라 변경될 수 있습니다.
                    </p>
                  </div>
                </div>
              )}

              {filterDataByTab().map((item) => (
                <Link
                  key={item.id}
                  to={`./detail?id=${item.id}`}
                  className="news_first news"
                >
                  <div className="news_img">
                    <img src={item.img || NoImage} alt="이미지" />
                  </div>
                  <div className="news_thumbnail">
                    <div
                      className={`news_badge contents3 ${
                        item.division === "OFFLINE" ? "badge_off" : ""
                      }`}
                    >
                      <span>{item.division}</span>
                    </div>
                    <p
                      className={`news_data ${
                        item.division === "OFFLINE" ? "data_off" : ""
                      }`}
                    >
                      {new Date(item.published_date).toLocaleDateString(
                        "ko-KR",
                        {
                          year: "numeric",
                          month: "long",
                          day: "numeric"
                        }
                      )}{" "}
                      {item.event_time && <span>| {item.event_time}</span>}
                    </p>
                    <p className="news_title">{item.title}</p>
                    <p className="news_content">{item.content_explain}</p>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Conference;
