export async function NewsLetterApi(options) {
  // const listId = "146545";
  // const groupId = "138313";
  // const token =
  //   "c8de0f3833ae56bbae4cc687cd2c04c5084f55cb94842b2740c75d344743e92469103648bd9bddebb3cf51a5d802184fe4083998470370280fc1c6d716575361";
  const email = options.email;
  const name = options.name;
  // console.log("받아온거 ", email, name);

  const requestData =
    // prettier-ignore
    {
      "eventOccuredBy": "MANUAL",
      "confirmEmailYN": "Y",
      "groupIds": [
        "333743"
       ],
      "subscribers": [
        {
          "email": email,
          "name": name
        }
      ]
    };

  const response = await fetch(
    `https://api.stibee.com/v1/lists/329380/subscribers`,
    {
      method: "POST",
      headers: {
        AccessToken:
          "c8de0f3833ae56bbae4cc687cd2c04c5084f55cb94842b2740c75d344743e92469103648bd9bddebb3cf51a5d802184fe4083998470370280fc1c6d716575361",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(requestData)
    }
  );

  if (!response.ok) {
    throw new Error("구독자 등록 실패");
  }

  const body = await response.json();
  console.log(body);
  return body;
}
