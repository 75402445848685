import React from "react";
import { Helmet } from "react-helmet";
import ScrollTop from "./ScrollTop";
import ScrollToTopButton from "./ScrollToTopButton";

//　css
import "../css/RpaPage.css";
import "../css/SupportPage.css";

// img
// import Content from "../components/ContentHeader";
import logoImg from "../assets/img/logo_white.png";
import bg1 from "../assets/img/main_bg6.png";
import bg2 from "../assets/img/main_bg7.png";
import iframe1 from "../assets/img/iframe1.png";
import iframe2 from "../assets/img/iframe2.png";
import iframe3 from "../assets/img/iframe3.png";
import iframe4 from "../assets/img/iframe4.png";
import iframe5 from "../assets/img/iframe5.png";
import iframe6 from "../assets/img/iframe6.png";
import newsLetterImg from "../assets/img/news_letter.png";

function ExpoPage2() {
  return (
    <div>
      <div className="rpa_page expo_page expo_faq">
        <ScrollTop />

        {/* 페이지 타이틀명 설정 */}
        <Helmet>
          <title>레인보우브레인 | 지능형자동화 전문기업</title>
        </Helmet>

        <div className="side_box">
          <div className="newsLetter">
            <a
              href="https://page.stibee.com/subscriptions/146545"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="newsLetter_wrap">
                <img src={newsLetterImg} alt="newsLetter" />
                <p className="sub_title4">NewsLetter</p>
              </div>
            </a>
          </div>
          <ScrollToTopButton />
        </div>

        <div className="expo_header">
          <a href="/" className="mobile_logo">
            <img src={logoImg} alt="로고 이미지" />
          </a>
        </div>

        <div className="content_bg">
          <img className="intro_bg intro_bg1" src={bg1} alt="bg_icon" />
          <img className="intro_bg intro_bg2" src={bg2} alt="bg_icon" />
          <div className="container">
            <div className="support_header">
              <h2 className="heading1">
                RPA 솔루션과 ChatGPT를 활용한 업무자동화 시연 영상
              </h2>
            </div>

            <div className="rpa2_iframe">
              <div className="rpa2_box">
                <a
                  href="http://m.site.naver.com/18atd"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={iframe1} alt="영상 썸네일" />
                </a>
                <p className="contents1">
                  이커머스의 상품 소개 페이지에서 허위 &middot; 과장 &middot;
                  불법광고의 상품을 모니터링하는 업무 
                </p>
              </div>

              <div className="rpa2_box">
                <a
                  href="http://m.site.naver.com/18atr"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={iframe2} alt="영상 썸네일" />
                </a>
                <p className="contents1">
                  이커머스 상품의 상품평(구매후기)을 감정 분석하여 정보를
                  제공하는 업무 
                </p>
              </div>

              <div className="rpa2_box">
                <a
                  href="http://m.site.naver.com/18atM"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={iframe3} alt="영상 썸네일" />
                </a>
                <p className="contents1">
                  이커머스의 상품 정보(가격)을 수집하여 수집된 정보 제공
                </p>
              </div>
            </div>

            <div className="rpa2_iframe">
              <div className="rpa2_box">
                <a
                  href="https://youtu.be/u2EYLv4Vzg8"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={iframe4} alt="영상 썸네일" />
                </a>
                <p className="contents1">
                  강연(회의)의 음성(영상)을 문자 형태의 요약본으로 작성하는 업무
                </p>
              </div>

              <div className="rpa2_box">
                <a
                  href="http://m.site.naver.com/18au1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={iframe5} alt="영상 썸네일" />
                </a>
                <p className="contents1">
                  회사의 규정 데이터를 활용하여 FAQ 제공하는 업무
                </p>
              </div>

              <div className="rpa2_box">
                <a
                  href="http://m.site.naver.com/18aua"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={iframe6} alt="영상 썸네일" />
                </a>
                <p className="contents1">
                  캘린더에 등록된 일정을 원하는 일정만 분류하여 매일 담당자에게
                  E-mail로 발송을 해주는 업무
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExpoPage2;
