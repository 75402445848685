import { BASE_URL } from "./base_url";

export async function getAllConference(options) {
  try {
    // fetch를 사용하여 API 엔드포인트에 요청
    const response = await fetch(
      `${BASE_URL}/conference/getallconferencelist`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        }
      }
    );

    // 응답이 성공적인지 확인
    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }

    // JSON 형식으로 응답을 파싱하여 반환
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching conference list:", error);
    // 에러가 발생할 경우 빈 배열이나 적절한 오류 메시지를 반환할 수 있습니다.
    return { error: "Failed to fetch conference list." };
  }
}

export async function getOneConference(id) {
  try {
    const response = await fetch(
      `${BASE_URL}/conference/getoneconference/${id}`
    );

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const conference = await response.json();
    return conference; // 가져온 회의 정보를 반환
  } catch (error) {
    console.error("Error fetching conference:", error);
    throw error; // 에러를 다시 던져 호출하는 곳에서 처리할 수 있도록 함
  }
}
