// import React, { useState, useEffect } from "react";

// function ScrollToTopButton() {
//   const [showButton, setShowButton] = useState(false);

//   function handleScroll() {
//     if (window.pageYOffset > 100) {
//       setShowButton(true);
//     } else {
//       setShowButton(false);
//     }
//   }

//   function handleClick() {
//     window.scrollTo({ top: 0, behavior: "smooth" });
//   }

//   // 스크롤 이벤트 리스너 등록
//   useEffect(() => {
//     window.addEventListener("scroll", handleScroll);
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   return (
//     <div>
//       {showButton && (
//         <a href='#none' className='go_top' onClick={handleClick}>
//           &uarr;
//         </a>
//       )}
//     </div>
//   );
// }

// export default ScrollToTopButton;

import React, { useState, useEffect } from "react";

import topIcon from "../assets/img/top_icon.png";

function ScrollToTopButton() {
  const [showButton, setShowButton] = useState(true);

  function handleScroll() {
    setShowButton(true);
  }

  function handleClick() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="side_wrap">
      {showButton && (
        <a href="#none" className="go_top" onClick={handleClick}>
          <img src={topIcon} alt="top icon" />
          TOP
        </a>
      )}
    </div>
  );
}

export default ScrollToTopButton;
