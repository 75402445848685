import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import ScrollTop from "./ScrollTop";
import Content from "../components/ContentHeader";
import Route from "../components/Route";

// css
import "../css/RpaPage.css";

//img
import rpa1 from "../assets/img/rpa1.png";
import rpa2 from "../assets/img/rpa2.png";
import rpa3 from "../assets/img/rpa3.png";
import rpa4 from "../assets/img/rpa4.png";
import rpa5 from "../assets/img/rpa5.png";
import rpa6 from "../assets/img/rpa6.png";
import rpa_icon1 from "../assets/img/rpa_icon1.png";
import rpa_icon2 from "../assets/img/rpa_icon2.png";
import rpa_icon3 from "../assets/img/rpa_icon3.png";
import rpa_icon4 from "../assets/img/rpa_icon4.png";
import rpa_icon5 from "../assets/img/rpa_icon5.png";
import rpa_icon6 from "../assets/img/rpa_icon6.png";
import rpa_icon7 from "../assets/img/rpa_icon7.png";
import rpa_icon8 from "../assets/img/rpa_icon8.png";
import plusIcon from "../assets/img/plus_icon.png";
import aiIcon1 from "../assets/img/ai_icon1.png";
import buttonArrow from "../assets/img/button_arrow_icon.png";

function RpaPage() {
  return (
    <div className="rpa_page">
      <ScrollTop />

      {/* 페이지 타이틀명 설정 */}
      <Helmet>
        <title>레인보우브레인 | 지능형자동화 전문기업</title>
        <meta
          name="description"
          content="업무자동화의 대표격인 로봇 소프트웨어 RPA를 소개합니다."
        ></meta>
      </Helmet>
      <Content title="RPA​" title1=""></Content>

      <Route route1="BUSINESS" route2="RPA"></Route>
      <div className="content_bg">
        <div className="container">
          <div className="cont1 space_between">
            <div className="cont1_div">
              <h2 className="heading1">Robotic Process Automation은</h2>
              <p className="heading3">
                사람이 수행하는 단순 반복 업무를 모방하여
                <br />
                동일한 방식으로 작업을 수행하는 소프트웨어 로봇입니다.
              </p>
              <p className="contents1">
                예를 들어 봇은 복사 붙여넣기, 웹 데이터 스크랩, 계산, 파일 열기
                및 이동, 이메일 구문 분석, 프로그램 로그인, API 연결, 구조화되지
                않은 데이터 추출 등의 작업을 수행할 수 있습니다.
                <br />
                <br />
                현재는 재무, 회계, 인사, 법무, 영업, 마케팅, 생산, 물류 등
                기업업무 전반에 걸쳐 적용되고 있으며, 최근 주 52시간 근무제
                도입, 생산연령인구 감소 등의 이유로 RPA가 업계의 화두로 떠오르고
                있습니다.
              </p>
            </div>

            <img src={rpa1} alt="RPA 이미지" />
          </div>

          <div className="cont2">
            <div>
              <div className="box">
                <img src={rpa_icon1} alt="아이콘 이미지" />
                <div className="column">
                  <p className="sub_title2">
                    로보틱 <span className="contents2">(Robotic)</span>​
                  </p>
                  <p className="contents1">사람이 일하는 방식을 모방​</p>
                </div>
              </div>
              <div className="box box_margin">
                <img src={rpa_icon2} alt="아이콘 이미지" />
                <div className="column">
                  <p className="sub_title2">
                    업무​ <span className="contents2">(Process)​</span>​
                  </p>
                  <p className="contents1">업무를 자동으로 수행하는 SW​​</p>
                </div>
              </div>
              <div className="box">
                <img src={rpa_icon3} alt="아이콘 이미지" />
                <div className="column">
                  <p className="sub_title2">
                    자동화 <span className="contents2">(Automation)​</span>​
                  </p>
                  <p className="contents1">비즈니스 관점의 자동화 기술​​</p>
                </div>
              </div>
            </div>
            <img src={plusIcon} className="plus_icon" alt="아이콘 이미지" />
            <div>
              <div className="box">
                <img src={rpa_icon4} alt="아이콘 이미지" />
                <div className="column">
                  <p className="sub_title2">인공지능</p>
                  <p className="contents1">Artificial ​Intelligence​​</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section2 container">
        <p className="heading1">RPA 적용 업무 기준​</p>
        <div className="space_between">
          <div className="box">
            <img src={rpa2} alt="RPA 이미지" />
            <p className="contents2">직원 다수가 수행하는</p>
            <span className="contents1">노동 집약적 업무</span>
          </div>
          <div className="box">
            <img src={rpa3} alt="RPA 이미지" />
            <p className="contents2">단순 규칙 기반의</p>
            <span className="contents1">반복 처리 업무</span>
          </div>
          <div className="box">
            <img src={rpa4} alt="RPA 이미지" />
            <p className="contents2">정형화된 데이터를 다루는</p>
            <span className="contents1">표준 처리 업무</span>
          </div>
          <div className="box">
            <img src={rpa5} alt="RPA 이미지" />
            <p className="contents2">업무 처리 지연으로</p>
            <span className="contents1">병목 현상이 있는 업무</span>
          </div>
        </div>
      </div>

      <div className="section3">
        <div className="container">
          <div className="cont1 space_evenly">
            <img src={rpa6} alt="RPA 이미지" />

            <div>
              <h2 className="heading1">RPA 기대효과​​</h2>
              <p className="heading3">
                RPA를 도입하면 기업의 다양한 목표
                <br />
                업무 생산성을 극대화 할 수 있습니다.​
              </p>
              <p className="sub_title4">
                사람들이 직접 처리하는 데 소요되는 업무시간과 에너지를 줄이고,
                <br />
                인력을 창의적인 업무에 배치하여 새로운 부가가치를 창출할 수
                있습니다.
                <br />
                <br />
                기업은 RPA를 통해 정확성, 신속성, 효율성, 경제성, 안정성 등과
                같은
                <br />
                다양한 효과를 기대할 수 있습니다.
              </p>
            </div>
          </div>
          <div className="cont2">
            <div className="box">
              <div className="img_box">
                <img src={rpa_icon5} alt="아이콘 이미지" />
              </div>
              <div className="text_box">
                <p className="sub_title4">효율적인 업무 처리</p>
                <p className="contents2">
                  RPA는 금융, 제조, 유통 등 다양한 분야에서 활용 가능합니다.
                </p>
              </div>
            </div>
            <div className="box">
              <div className="img_box">
                <img src={rpa_icon6} alt="아이콘 이미지" />
              </div>
              <div className="text_box">
                <p className="sub_title4">비용 절감</p>
                <p className="contents2">
                  RPA는 저렴한 비용으로 24시간 업무 가능한 노동력을 확보합니다.
                </p>
              </div>
            </div>
            <div className="box">
              <div className="img_box">
                <img src={rpa_icon7} alt="아이콘 이미지" />
              </div>
              <div className="text_box">
                <p className="sub_title4">데이터 보안 강화​</p>
                <p className="contents2">
                  RPA는 데이터 통제 및 규정 준수에 용이합니다.
                </p>
              </div>
            </div>
            <div className="box">
              <div className="img_box">
                <img src={rpa_icon8} alt="아이콘 이미지" />
              </div>
              <div className="text_box">
                <p className="sub_title4">업무 확장성</p>
                <p className="contents2">
                  RPA는 모든 환경에서 작업 수행이 가능하여 단일장치로 제어가
                  가능합니다.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="content_bg">
        <div className="container">
          <div className="page_box">
            <img src={aiIcon1} alt="아이콘 이미지" />
            <div className="text_box">
              <p className="heading2">RBrain RPA Portal</p>
              <p className="contents1">
                RPA를 통한 자동화 업무를 보다 쉽고 편리하게 운영, 관리할 수 있는
                RPA 통합 관리 플랫폼입니다.
              </p>

              <NavLink
                to="/business/rpaportal"
                className="blue_btn button1 btn_margin"
              >
                RPA Portal 바로 가기
                <img src={buttonArrow} alt="RPA Portal 바로가기" />
              </NavLink>
              {/* <NavLink to="/company/support" className="blue_btn button1">
                FAQ 바로 가기
                <img src={buttonArrow} alt="RPA Portal 바로가기" />
              </NavLink> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RpaPage;
