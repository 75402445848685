import React from "react";
import letterIcon from "../assets/img/letter_icon.png";

function NewsLetter({ onClick }) {
  const handleClick = () => {
    if (typeof onClick === "function") {
      onClick();
    }
  };
  
  return (
    <div className="side_wrap newsletter" onClick={handleClick}>
      <div className="newsletter">
        <img src={letterIcon} alt="letter icon" />
        뉴스레터
      </div>
    </div>
  );
}

export default NewsLetter;
