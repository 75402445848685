import React from "react";
import { Helmet } from "react-helmet";
import { useState } from "react";
import { getAuthToken_2 } from "../api/api.js";
import ScrollTop from "./ScrollTop";
import ScrollToTopButton from "./ScrollToTopButton";

// css
import "../css/RpaPage.css";
import "../css/SupportPage.css";

// o,g
import Content from "../components/ContentHeader";
import logoImg from "../assets/img/logo_white.png";
import buttonArrow from "../assets/img/button_arrow_icon.png";
import loadingImg from "../assets/img/loading.png";
import newsLetterImg from "../assets/img/news_letter.png";

function ExpoPage1() {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const handleButtonClick = (event) => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setIsButtonDisabled(false);
    }, 105000);
    event.preventDefault(); //버튼 비활성화
    setIsButtonDisabled(true);

    console.log(event.target.name);

    // event.target.name : 1(금칙어1) , 2(금칙어2), 3(금칙어3)
    const inputData = {
      input1: event.target.name,
    };

    getAuthToken_2(inputData); // RPA를 실행하는 함수.
  };

  return (
    <div className="rpa_page expo_page expo_faq">
      <ScrollTop />

      {/* 페이지 타이틀명 설정 */}
      <Helmet>
        <title>레인보우브레인 | 지능형자동화 전문기업</title>
      </Helmet>

      <div className="expo_header">
        <a href="/" className="mobile_logo">
          <img src={logoImg} alt="로고 이미지" />
        </a>
      </div>

      <Content title="RPA 솔루션과 ChatGPT를 활용한 업무자동화 시연"></Content>
      <div className="side_box">
        <div className="newsLetter">
          <a
            href="https://page.stibee.com/subscriptions/146545"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="newsLetter_wrap">
              <img src={newsLetterImg} alt="newsLetter" />
              <p className="sub_title4">NewsLetter</p>
            </div>
          </a>
        </div>
        <ScrollToTopButton />
      </div>
      {isLoading && (
        <div>
          <div className="bg_loading"></div>
          <div className="loading_wrap">
            <img src={loadingImg} alt="loading" />
            <div className="loading">
              <span></span>
              <span></span>
              <span></span>
            </div>
            <p className="heading2">
              옆 모니터에서
              <br />
              <span>진행사항</span>을 확인 해 주세요.
            </p>
          </div>
        </div>
      )}
      <div className="content_bg">
        <div className="container">
          <div className="support_header">
            <h2 className="heading1">금칙어 검수</h2>
          </div>
          <div className="faq_list">
            <div className="expo_content1">
              <p className="sub_title4">
                URL :
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.coupang.com/vp/products/7267897799?itemId=18528656615"
                >
                  &nbsp;[https://www.coupang.com/vp/products/7267897799?itemId=18528656615]
                </a>
                <br />
                조건 : 해당 제품이 질병 치료 또는 암 치료에 효과가 있다는 표현이
                있는지 확인
                <br />
                키워드 : 암
              </p>
              <div>
                <button
                  className="blue_btn button1"
                  onClick={handleButtonClick}
                  disabled={isButtonDisabled}
                  name="1"
                >
                  검수하기
                  <img src={buttonArrow} alt="검수하기" />
                </button>
              </div>
            </div>
          </div>
          <div className="faq_list">
            <div className="expo_content1">
              <p className="sub_title4">
                URL :{" "}
                <a
                  href="https://www.qoo10.com/g/543844475/Q107552235"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  [https://www.qoo10.com/g/543844475/Q107552235]
                </a>
                <br />
                조건 : 해당 제품을 의약품으로 판매하는지 확인
                <br />
                키워드 : 의약품
              </p>
              <div>
                <button
                  className="blue_btn button1"
                  onClick={handleButtonClick}
                  disabled={isButtonDisabled}
                  name="2"
                >
                  검수하기
                  <img src={buttonArrow} alt="검수하기" />
                </button>
              </div>
            </div>
          </div>
          <div className="faq_list">
            <div className="expo_content1">
              <p className="sub_title4">
                URL :{" "}
                <a
                  href="https://www.coupang.com/vp/products/6976261685?itemId=17028727760"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  [https://www.coupang.com/vp/products/6976261685?itemId=17028727760]
                </a>
                <br />
                조건 : 해당 제품이 증상과 관련하여 효과가 있음을 표현하는지 확인
                <br />
                키워드 : 장애, 문제
              </p>
              <div>
                <button
                  className="blue_btn button1"
                  onClick={handleButtonClick}
                  disabled={isButtonDisabled}
                  name="3"
                >
                  검수하기
                  <img src={buttonArrow} alt="검수하기" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExpoPage1;
