import React from "react";
import { Helmet } from "react-helmet";
// import React, { useState, useEffect } from "react";
// import { getCase } from "../api/api.js";
import ScrollTop from "./ScrollTop";
import Content from "../components/ContentHeader.js";
import Route from "../components/Route.js";

// css
import "../css/RPA_Portal.css";

//img
import intro_icon1 from "../assets/img/portal_icon1.png";
import intro_icon2 from "../assets/img/portal_icon2.png";
import intro_icon3 from "../assets/img/portal_icon3.png";
import quotes_left from "../assets/img/icon_quotes_left.png";
import quotes_right from "../assets/img/icon_quotes_right.png";
import download from "../assets/img/download_icon.png";
import portalIcon4 from "../assets/img/portal_icon4.png";
import portalIcon5 from "../assets/img/portal_icon5.png";
import portalIcon6 from "../assets/img/portal_icon6.png";
import portalIcon7 from "../assets/img/portal_icon7.png";
import portalIcon8 from "../assets/img/portal_icon8.png";
import portalIcon9 from "../assets/img/portal_icon9.png";
import bg1 from "../assets/img/main_bg1.png";
import bg2 from "../assets/img/main_bg2.png";

function RPAPortal() {
  const handleCmpanyProfile = async () => {
    window.open(
      "http://file.rbrain.co.kr/2024-10-15T01-28-11-408Z-Rbrain%20RPA%20Portal%20%EC%A0%9C%ED%92%88%20%EC%86%8C%EA%B0%9C%EC%84%9C.pdf",
      "_blank"
    );
  };

  // const handleCompanyProfile = async () => {
  //   alert("준비중입니다.");
  // };

  const data = [
    {
      title: "Bot 라이프사이클\n관리",
      items: ["손쉬운 과제관리", "타임라인 형태의 Bot 스케줄 확인"],
      description:
        "업무 발굴부터 개발, 폐기, 운영까지 전 과정을 통합∙일률적으로 관리할 수 있어 업무의 효율성을 극대화합니다.<br /><br />현업 담당자가 RPA 대상 업무를 제안하면 관리자는 적합도에 따라 심사 후 최종 선정 또는 폐기할 수 있으며, 운영자는 Bot 실행 현황을 실시간으로 트래킹하고 확인할 수 있습니다.",
    },
    {
      title: "Bot 모니터링\n및 장애 관리​",
      items: [
        "Bot 별 가동시 및 가동률 제공​",
        "장애 추적 및 매뉴얼 작성 기능제공​",
      ],
      description:
        "Bot 통합 로깅을 통한 업무 실행 현황과 Bot 장비 상태 및 지표를 실시간으로 모니터링하여 안정적인 운영을 돕습니다.<br /><br />특히 Bot 수행 중 비정상으로 종료된 경우, 에러 원인 파악을 위해 상세 수행 기록을 제공함으로써 장애 사고에 신속하게 대응합니다. ​",
    },
    {
      title: "ROI 관리와 각종 통계\n정보 제공​",
      items: ["목표 달성 현황 확인", "성과분석 통합 리포트로 활용​"],
      description:
        "사용자 경험을 고려한 대시보드와 시각화 도구를 통해 RPA 운영 데이터와 실시간 통계정보를 제공합니다.<br /><br />RPA Bot의 수행 기간과 업무 절감시간 및 실적정보를 그래프로 확인할 수 있어 ROI를 즉각적으로 측정할 수 있습니다. ​​",
    },
    {
      title: "커뮤니티\n채널 마련​",
      items: ["공지사항, 자료실, Q&A 운영​", "업무용 협업툴로 활용​"],
      description:
        "현업 담당자, 관리자, 운영자 간 RPA와 관련된 모든 정보를 제공하고 공유할 수 있습니다.<br /><br />RPA 소통채널을 하나로 통합함으로써 업무 효율성을 높이고 원활한 협업을 돕습니다.​​",
    },
  ];

  return (
    <div className="portal_wrap">
      <ScrollTop />

      {/* 페이지 타이틀명 설정 */}
      <Helmet>
        <title>레인보우브레인 | 지능형자동화 전문기업</title>
        <meta
          name="description"
          content="RPA를 보다 쉽고 편리하고 운영, 관리할 수 있는 통합 관리 플랫폼입니다."
        ></meta>
      </Helmet>

      <Content title="RBrain RPA Portal"></Content>

      <Route route1="BUSINESS" route2="RPA PORTAL"></Route>
      <div className="content_bg">
        <div className="container portal_intro">
          <h2 className="heading1">서비스 소개</h2>

          <div className="portal_intro_img">
            <ul className="portal_intro_wrap">
              <li className="portal_intro_item">
                <img src={intro_icon1} alt="icon1" />

                <p className="sub_title3">운영 관리</p>
              </li>

              <li className="portal_intro_item">
                <img src={intro_icon2} alt="icon2" />

                <p className="sub_title3">성과 관리</p>
              </li>

              <li className="portal_intro_item">
                <img src={intro_icon3} alt="icon3" />

                <p className="sub_title3">추진 관리</p>
              </li>
            </ul>
          </div>

          <div className="portal_intro_text">
            <h3 className="heading2">
              RBrain RPA Portal은 RPA를 통한 자동화 업무를 보다 쉽고 편리하게
              운영, 관리할 수 있는 RPA 통합 관리 플랫폼입니다.
            </h3>

            <p className="sub_title4">
              RPA Bot과 Task를 효율적으로 관리하여 업무의 생산성을 향상시키고
              RPA 운영현황 데이터, 실시간 통계,
              <br />
              Bot 모니터링, 사용자 커뮤니티 등 다양한 기능을 제공하여 거버넌스
              체계 강화를 지원합니다.
              <br />
              또한 RPA를 전사적으로 확산하고 내재화를 추진케 하는데 용이하며,
              <br />
              추후 Citizen Developer(시민 개발자) 양성을 위한 플랫폼으로 활용
              가능합니다.
            </p>
          </div>

          <div className="blue_btn">
            <span className="quotes quotes_left">
              <img src={quotes_left} alt="quotes_left" />
            </span>

            <p className="sub_title2">
              RBrain RPA Portal은 RPA의 운영/성과/추진(내재화)을
              <br />
              통합 관리하여 체계적인 업무환경을 제공합니다.
            </p>

            <span className="quotes quotes_right">
              <img src={quotes_right} alt="quotes_right" />
            </span>
          </div>
        </div>
      </div>

      <div className="sec2">
        <div className="container">
          <h1 className="heading1">주요 기능</h1>
          <div className="sec2_box_wrap">
            <div class="sec2_box">
              <div class="box">
                <div class="num contents1">01</div>
                <img src={portalIcon4} alt="이미지" />
                <p className="sub_title4">누구나 RPA 대상 업무 제안​</p>
              </div>
              <div class="box">
                <div class="num contents1">02</div>
                <img src={portalIcon5} alt="이미지" />
                <p className="sub_title4">
                  KPI 기반 평가에 따라
                  <br />
                  제안 업무 평가 후 등급 설정​
                </p>
              </div>
              <div class="box">
                <div class="num contents1">03</div>
                <img src={portalIcon6} alt="이미지" />
                <p className="sub_title4">
                  대시보드에서 선정된
                  <br />
                  업무의 ROI 관리​
                </p>
              </div>
            </div>
            <div class="sec2_box">
              <div class="box">
                <div class="num contents1">04</div>
                <img src={portalIcon7} alt="이미지" />
                <p className="sub_title4">Bot의 운영 현황 및 성과 모니터링​</p>
              </div>
              <div class="box">
                <div class="num contents1">05</div>
                <img src={portalIcon8} alt="이미지" />
                <p className="sub_title4">
                  Bot 등록 및 이용 시<br />
                  컴플라이언스 규칙 지정​
                </p>
              </div>
              <div class="box">
                <div class="num contents1">06</div>
                <img src={portalIcon9} alt="이미지" />
                <p className="sub_title4">
                  Bot이 정지했을 경우의
                  <br />
                  대처 방법 등의 정보 공유​
                </p>
              </div>
            </div>
          </div>

          <h2 className="heading2">소프트웨어/하드웨어 필요 사양​</h2>
          <div className="left">
            <p className="sub_title3">
              <span className="dot"></span>WAS
            </p>
            <table className="sub_title4">
              <thead>
                <tr>
                  <td></td>
                  <th>Spec</th>
                  <th>비고</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <th>OS</th>
                  <td>
                    Windows 2016
                    <br />
                    Windows 10
                    <br />
                    LINUX(ubuntu 18 이상, CentOS 7 이상)
                  </td>
                  <td>PowerShell 5.0 이상</td>
                </tr>
                <tr>
                  <th>CPU</th>
                  <td>4 Core</td>
                  <td>물리 코어 수 기준</td>
                </tr>
                <tr>
                  <th>MEMORY</th>
                  <td>2GB</td>
                  <td>최소 / 가용가능한 메모리 (OS 점유 제외)</td>
                </tr>
                <tr>
                  <th>Storage</th>
                  <td>512GB</td>
                  <td>권장</td>
                </tr>
                <tr>
                  <th>JAVA</th>
                  <td>OpenJDK17</td>
                  <td></td>
                </tr>
              </tbody>
            </table>

            <p className="sub_title3">
              <span className="dot"></span>Automation Anywhere Control Room
            </p>
            <div className="aa_box">
              <div className="sub_title4">
                <p>&middot;&nbsp;&nbsp;최소 v24 이상</p>
                <p>&middot;&nbsp;&nbsp;Log4Shell 대응 </p>
              </div>
              <p className="t_right contents1">
                본 솔루션은 Automation Anywhere 사용 고객에 한해 제공됩니다.​
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="sec3">
        <div className="container">
          <h2 className="heading1">서비스 특징</h2>
          <div className="d-grid">
            {data.map((box, index) => (
              <div className="box" key={index}>
                <div className="header_box sub_title3">
                  {box.title.split("\n").map((line, idx) => (
                    <div key={idx}>{line}</div>
                  ))}
                </div>
                <div className="contents1">
                  {box.items.map((item, idx) => (
                    <p key={idx}>
                      <span className="dot2"></span>
                      {item}
                    </p>
                  ))}
                  <p
                    className="text"
                    dangerouslySetInnerHTML={{ __html: box.description }}
                  ></p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="intro_video">
        <div className="container">
          <h2 className="heading1">서비스 소개 영상</h2>
          <div className="iframe">
            <iframe
              src="https://www.youtube.com/embed/JEgWAGks5dQ?si=s4PzDAtYx2nLBGrE&amp;controls=0"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>

          <div>
            <button className="blue_btn button1" onClick={handleCmpanyProfile}>
              매뉴얼 다운로드
              <img src={download} alt="download" />
            </button>
          </div>
        </div>
        {/* 뒷배경 도형 */}
        <img className="news_bg1 news_bg" src={bg1} alt="bg" />
        <img className="news_bg2 news_bg" src={bg2} alt="bg1" />
      </div>
    </div>
  );
}

export default RPAPortal;
