/*
 * ──────────────────────────────────────────────────────
 * # 페이징 컴포넌트 #
 *
 * 페이지네이션 라이브러리 설치
 * npm i react-js-pagination
 *
 *
 * 페이징 부분은 수정많이 필요함
 * 아이템 개수에 따라서 변경되는게 아니고 backend에서 offset , limit으로
 * 아이템 개수설정 되는 중(2023,02,14)
 * ______________________________________________________
 */

import React from "react";

// -- 페이지네이션 -- //
import Pagination from "react-js-pagination";

// -- css -- //
import "../css/Paging.css";

//______________________________________________________

const Paging = ({
  items,
  page,
  handlePaging,
  totalItemsCount,
  itemsCountPerPage,
  pageRangeDisplayed,
}) => {
  return (
    <Pagination
      items={items}
      activePage={page} // 현재 페이지
      itemsCountPerPage={itemsCountPerPage} // 한 페이지당 보여줄 리스트 아이템의 개수
      totalItemsCount={totalItemsCount} // 총 아이템의 개수
      pageRangeDisplayed={pageRangeDisplayed} // Paginator 내에서 보여줄 페이지의 범위
      prevPageText={"‹"}
      nextPageText={"›"}
      onChange={handlePaging} // 페이지가 바뀔 때 핸들링 해줄 함수
    />
  );
};

export default Paging;
