/* eslint-disable */
import React, { useState, useEffect } from "react";
import { getPopups } from "../api/api.js";

// css
import "../css/Popup.css";

const Popup = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [data, setData] = useState([]);
  const [openPopups, setOpenPopups] = useState([]);

  const handleClose = (popupIndex) => {
    setOpenPopups((prevOpenPopups) => {
      const updatedOpenPopups = [...prevOpenPopups];
      updatedOpenPopups[popupIndex] = false;
      setIsOpen(false);
      return updatedOpenPopups;
    });
  };

  const handleCloseTimeLimit = (index) => {
    handleClose(index);

    const disabledUntil = new Date().getTime() + 3 * 60 * 60 * 1000; // 3시간 제한

    const popupStates = JSON.parse(localStorage.getItem("popupStates")) || {};
    popupStates[index] = disabledUntil;
    localStorage.setItem("popupStates", JSON.stringify(popupStates));
  };

  // console.log(data);

  const handleLoad = async () => {
    const result = await getPopups();
    setData(result);
  };

  useEffect(() => {
    handleLoad();
  }, []);

  useEffect(() => {
    const storedPopupStates =
      JSON.parse(localStorage.getItem("popupStates")) || {};
    const updatedOpenPopups = data.map((_, index) => {
      const disabledUntil = storedPopupStates[index] || 0;
      return new Date().getTime() > disabledUntil;
    });
    setOpenPopups(updatedOpenPopups);
  }, [data]);

  return (
    <div className="popup">
      {openPopups.map(
        (isOpen, index) =>
          isOpen && (
            <div key={index} className="relative">
              <img src={data[index].fileUrl} alt="Popup Image" />
              <a
                href={data[index].url}
                target="_blank"
                rel="noopener noreferrer"
                className="link"
              ></a>
              <button onClick={() => handleClose(index)} className="close">
                &times;
              </button>
              <button
                onClick={() => handleCloseTimeLimit(index)}
                className="close-timeLimit"
              >
                □ 3시간 동안 열지 않기
              </button>
            </div>
          )
      )}
    </div>
  );
};

export default Popup;
