// NewsLetterPaging.jsx

import { useEffect, useRef } from "react";
import Pagination from "react-js-pagination";

import "../css/NewsLetterPage.css";

const NewsLetterPaging = ({ items, page, limit, handlePaging }) => {
  const secondLastPageButtonRef = useRef(null);

  useEffect(() => {
    addCustomClassToPagination();
  }, [page]);

  const addCustomClassToPagination = () => {
    const paginationList = document.querySelectorAll("ul.pagination li");
    const secondLastPageButton = paginationList[paginationList.length - 2];
    if (paginationList) {
      secondLastPageButtonRef.current = secondLastPageButton; // Ref에 버튼 요소 저장
    }
  };

  return (
    <Pagination
      activePage={page}
      itemsCountPerPage={limit}
      totalItemsCount={items.length}
      pageRangeDisplayed={5}
      prevPageText={"‹"}
      nextPageText={"›"}
      onChange={handlePaging} // 페이지가 바뀔 때 핸들링 해줄 함수
    />
  );
};

export default NewsLetterPaging;
