import { Helmet } from "react-helmet";
import { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import { getLetter, getLetterCount } from "../api/Api_newslettermanage";
import ScrollTop from "./ScrollTop";
import Content from "../components/ContentHeader";
import NewsLetterPaging from "../components/NewsLetterPaging";
import Route from "../components/Route";
import NewsLetterViewBoard from "../pages/NewsLetterViewBoard";

// css
import "../css/newsletter.css";

function NewsLetterViewPage() {
  // const navigate = useNavigate();
  const [showItems, setShowItems] = useState([]);
  const [items, setItems] = useState([]);
  const [totalItemsCount, setTotalItemsCount] = useState(0);

  const limitOption = [10, 30, 50];
  const [limit, setLimit] = useState(limitOption[0]);
  const [page, setPage] = useState(1);
  const [offset, setOffset] = useState(0);

  const handlePaging = (page) => {
    setPage(page); // 페이지 번호

    if (page === 1) {
      setOffset(0);
    } else {
      setOffset((page - 1) * limit);
    }
  };

  const handleLoad = async (options) => {
    const news = await getLetter(options);
    setShowItems(news); //pagination용

    const newsCount = await getLetterCount(options);

    setItems(newsCount); //boardA용
    setTotalItemsCount(newsCount.length); //boardA용
  };

  useEffect(() => {
    handleLoad({
      offset: offset,
      limit: limit
    });
  }, [offset, limit]);

  const handleChange = (e) => {
    e.preventDefault();
    const newLimit = parseInt(e.target.value, 10); // 새로운 limit 값
    setLimit(newLimit);
    setPage(1); // 페이지를 1로 설정
    setOffset(0); // 오프셋을 0으로 설정
    handleLoad({ offset: 0, limit: newLimit });
  };

  const SelectBoxRow = ({ options, handleChange, value }) => {
    return (
      <select
        className="SelectBoxRow"
        name="select_Box_Row"
        onChange={handleChange}
        value={value}
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {option}건
          </option>
        ))}
      </select>
    );
  };

  return (
    <div className="news_detail_wrap">
      <ScrollTop />

      {/* 페이지 타이틀명 설정 */}
      <Helmet>
        <title>레인보우브레인 | 지능형자동화 전문기업</title>
        <meta
          name="description"
          content="AI 소식, 최신 기술 등 관련된 최신 뉴스를 제공합니다."
        ></meta>
      </Helmet>

      <Content
        title="NEWSLETTER"
        title1="(주)레인보우브레인에서는 매월 최신기술동향 및 고객사례와 실무에 도움이 되는 정보를 전해 드리고 있습니다."
      ></Content>

      <Route route1="MEDIA" route2="NEWSLETTER"></Route>

      <div className="content_bg fff">
        <div className="container">
          <h2 className="heading1">RPA+AI 뉴스레터​</h2>

          <div className="newsletter_wrap">
            <div className="d-start">
              <div className="d-flex">
                총 <div className="option_color">{totalItemsCount}</div> 건
              </div>
              <SelectBoxRow
                options={limitOption}
                handleChange={handleChange}
                value={limit}
              />
            </div>

            <NewsLetterViewBoard
              items={showItems}
              totalItemsCount={totalItemsCount}
              currentPage={page}
              itemsPerPage={limit}
            />

            {/* 페이징 컴포넌트 */}
            <NewsLetterPaging
              items={items}
              page={page}
              limit={limit}
              handlePaging={handlePaging}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewsLetterViewPage;
