import { NavLink, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getNews } from "../api/api.js";
import { news_click } from "../api/Api_newslettermanage.js";

// css
import "../css/News.css";

// img
import main_thumb1 from "../assets/img/no_image.png";
import buttonArrow from "../assets/img/button_arrow_icon.png";
import titleIcon from "../assets/img/icon_title_text.png";
import bg1 from "../assets/img/main_bg1.png";
import bg2 from "../assets/img/main_bg2.png";
import m_select_icon from "../assets/img/mobile_select_arrow.png";

function NEWS() {
  // const tab = ["ALL", "RAINBOW BRAIN", "RPA", "AUTOMATION ANYWHERE"];
  const tab = ["ALL", "RAINBOW BRAIN", "AUTOMATION ANYWHERE"];

  const [data, setData] = useState([]);
  const [renderData, setRenderData] = useState([...data]);
  const [activeIndex, setActiveIndex] = useState(0); // 선택된 li의 인덱스

  const navigate = useNavigate();

  const slicedList = (data) => {
    const isMobile = window.innerWidth < 600;
    return data?.slice(0, isMobile ? 4 : 8);
  };

  const handleClick = (e) => {
    const index = tab.indexOf(e.target.textContent.toUpperCase()); // 선택된 li의 인덱스 계산
    if (index === -1) return; // tab 배열에 없는 값이면 무시
    setActiveIndex(index); // 선택된 li의 인덱스 저장
    if (e.target.textContent.toLowerCase() === "all") {
      setRenderData(data);
    } else {
      const filter = [...data].filter((item) =>
        item.division.includes(e.target.textContent)
      );
      setRenderData(filter);
    }
  };

  const handleLoad = async () => {
    const news = await getNews();
    setData(news);
    setRenderData(news);
  };

  const handleClickDetail = (item, index) => {
    // navigate(`./media/news/newsDetail`, { state: { item } });
    // console.log(item);
    const currentCount = item.click_count ? parseInt(item.click_count, 10) : 0;
    const uploadData = {
      click_count: currentCount + 1
    };
    news_click(item.id, uploadData);

    navigate(`/media/news/detail?id=${item.id}`, {
      state: { index, renderData } // 이전다음버튼때문에 통째로넘김
    });
  };

  useEffect(() => {
    handleLoad();
  }, []);

  let [toggle, setToggle] = useState(false);

  return (
    <div className="news_container">
      <div className="container">
        <div className="news_wrap">
          <div className="title">
            <img src={titleIcon} alt="titleIcon" />
            <span className="heading1">NEWS</span>

            <ul className="contents1 news_list">
              {tab.map((item, index) => {
                const active = index === activeIndex ? "news_active" : "";
                // const last = index === 4 ? "last" : "";
                const last = index === 3 ? "last" : "";
                return (
                  <li
                    key={index}
                    onClick={handleClick}
                    className={`${last} ${active}`}
                  >
                    <span>{item}</span>
                  </li>
                );
              })}
            </ul>

            <div className="m_news_list_wrap">
              <button
                className="m_news_select"
                onClick={() => {
                  setToggle(!toggle);
                }}
              >
                <p className="sub_title4">ALL</p>
                <img
                  className="m_select_icon"
                  src={m_select_icon}
                  alt="m_select_icon"
                />
              </button>
              {toggle === true ? (
                <ul className="contents1 m_news_list">
                  {tab.map((item, index) => {
                    const first = index === 0 ? "first" : "";
                    return (
                      <li key={index} onClick={handleClick} className={first}>
                        <span>{item}</span>
                      </li>
                    );
                  })}
                </ul>
              ) : null}
            </div>
          </div>

          {slicedList(renderData).map((item, index) => (
            <div
              key={item.id}
              onClick={() => handleClickDetail(item, index)}
              style={{ display: "inline" }}
            >
              <Card
                image={item.fileUrl ? item.fileUrl : main_thumb1}
                key={item.id}
                category={item.division}
                title={item.title}
                content={item.content}
                summary_content={item.summary_content}
                tab={tab}
              />
            </div>
          ))}

          <div style={{ textAlign: "center" }}>
            <NavLink to="/media/news">
              <button className="blue_btn button1">
                소식 더 보러가기
                <img src={buttonArrow} alt="소식 더 보러가기" />
              </button>
            </NavLink>
          </div>
        </div>
      </div>

      {/* 뒷배경 도형 */}
      <img className="news_bg1 news_bg" src={bg1} alt="bg" />
      <img className="news_bg2 news_bg" src={bg2} alt="bg1" />
    </div>
  );
}

function Card(props) {
  const isCategoryValid = props.tab.includes(props.category.toUpperCase());

  return isCategoryValid ? (
    <div className="news">
      <div className="news_img">
        <img src={props.image} alt="썸네일" />
      </div>
      <div className="news_thumbnail">
        <div className="news_badge contents3">
          <span>{props.category}</span>
        </div>
        <p className="sub_title4 news_title">{props.title}</p>

        {/* 요약내용이 있으면 요약내용 우선 출력 */}
        <p
          className="contents2 news_content"
          dangerouslySetInnerHTML={{
            __html:
              props.summary_content ||
              props.content
                .replace(/&nbsp;/g, "<br>")
                .replace(/<figure[^>]*>.*<\/figure>/g, "")
                .replace(/<img[^>]*>/g, "")
          }}
        ></p>
      </div>
    </div>
  ) : null;
}

export default NEWS;
