import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { getCase } from "../api/api.js";
import Content from "../components/ContentHeader.js";
import Route from "../components/Route.js";

// css
import "../css/CasePage.css";

// img
import buttonArrow from "../assets/img/news_btn_arrow.png";

function CasePageDetail() {
  const location = useLocation();
  const { item } = location.state;

  const [detail_title, setDetail_title] = useState(item.title);
  const [detail_summary_content, setDetail_summary_content] = useState(
    item.summary_content
  );

  const [detail_content, setDetail_content] = useState(item.content);

  const [detail_item, setDetail_item] = useState([]);

  const [itemId, setItemId] = useState(item.id);

  // 최초 렌더링하면서 뉴스데이터를 caseDetail 가져오는 함수.
  const handleLoad = async () => {
    const caseDetail = await getCase();
    setDetail_item(caseDetail);
  };

  // 다음 버튼 클릭할 때 실행함수,
  const handleNextButtonClick = () => {
    const currentIndex = detail_item.findIndex((i) => i.id === itemId);
    const nextIndex = currentIndex + 1;
    setItemId(detail_item[nextIndex].id);
    setDetail_title(detail_item[nextIndex].title);
    setDetail_summary_content(detail_item[nextIndex].summary_content);
    setDetail_content(detail_item[nextIndex].content);
  };

  // 뒤로 버튼 클릭할 때 실행함수,
  const handlePrevButtonClick = () => {
    const currentIndex = detail_item.findIndex((i) => i.id === itemId);
    const nextIndex = currentIndex - 1;
    setItemId(detail_item[nextIndex].id);
    setDetail_title(detail_item[nextIndex].title);
    setDetail_summary_content(detail_item[nextIndex].summary_content);
    setDetail_content(detail_item[nextIndex].content);
  };

  useEffect(() => {
    handleLoad();
  }, []);

  return (
    <>
      <Content title="구축사례​​​​"></Content>

      <Route route1="RAINBOW BRAIN" route2="NEWS"></Route>
      <div className="content_bg">
        <div className="container">
          <div className="news_box">
            <p className="sub_title2 news_title">{detail_title}</p>
            <p
              className="contents2 news_content"
              dangerouslySetInnerHTML={{
                __html:
                  detail_summary_content ||
                  detail_content.replace(/&nbsp;/g, "<br>"),
              }}
            ></p>

            <div className="news_btn">
              <button
                className="btn_prev contents3"
                onClick={handlePrevButtonClick}
              >
                <img src={buttonArrow} alt="이전" />
                Prev
              </button>
              <Link to="/media/case">
                <button className="btn_list contents3 ">목록</button>
              </Link>
              <button
                className="btn_next contents3"
                onClick={handleNextButtonClick}
              >
                Next
                <img src={buttonArrow} alt="다음" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CasePageDetail;
