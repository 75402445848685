import React from "react";
import { NavLink } from "react-router-dom";
import ScrollTop from "./ScrollTop";

// css
import "../css/App.font.css";
import "../css/AiExpo.css";

// img
import Logo from "../assets/img/logo_white.png";
import arrowBtn from "../assets/img/arrow_icon.png";
import imsi01 from "../assets/img/imsi01.jpg";
import imsi02 from "../assets/img/imsi02.jpg";
import imsi03 from "../assets/img/imsi03.jpg";

function AiExpo() {
  const data = [
    {
      image: imsi01,
      title: "Eureka LLM & RAG",
      items: (
        <>
          <p>인공지능이 업무를 지원하는 미래,</p>
          <p>멀지 않은 내일!</p>
          <br />
          <p>RAG 및 KB 기반 기업 특화 LLM</p>
        </>
      ),
      buttonName: "소개",
      link: "/aiexpoLLM",
    },
    {
      image: imsi02,
      title: "Eureka e-Commerce",
      items: (
        <>
          <p>반복 업무를 편안하게 도와주는</p>
          <p>이커머스 마케팅 길잡이</p>
          <br />
          <p>상품 정보 수집 및 분석 자동화 솔루션</p>
        </>
      ),
      buttonName: "소개",
      link: "/aiexpoECommerce",
    },
    {
      image: imsi03,
      title: "RPA+ChatGPT 업무 자동화​",
      items: (
        <>
          <p>업무효율성의 새로운 표준,</p>
          <p>더 나은 업무 방식을 경험하세요.</p>
          <br />
          <p>혁신을 이끄는 RPA와 ChatGPT의 결합​</p>
        </>
      ),
      buttonName: "사례",
      link: "/aiexpoChatGPT",
    },
  ];

  return (
    <div className="wrap" style={{ height: "100vh" }}>
      <ScrollTop />

      <div className="header d-flex">
        <div className="expo_container d-between">
          <h1 className="heading1">AI EXPO KOREA 2024</h1>
          <div className="d-flex">
            <img src={Logo} alt="로고 이미지" />
            <p className="contents1">
              <span>&#124;</span>Lead Coexistence of Human and AI in the
              Workflow
            </p>
          </div>
        </div>
      </div>

      <div className="expo_container">
        <div className="ai_wrap d-flex">
          {data.map((box, index) => (
            <div className="ai_box" key={index}>
              <img className="ai_img" src={box.image} alt={"이미지"} />
              <div className="sub_title2">
                {box.title.split("\n").map((line, idx) => (
                  <div key={idx}>{line}</div>
                ))}
              </div>
              <div className="contents2">{box.items}</div>
              <NavLink to={box.link} className="ai_btn button1 d-flex">
                {box.buttonName} 영상 보러 가기
                <img src={arrowBtn} alt="arrow icon" />
              </NavLink>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AiExpo;
