import React, { useState, useEffect } from "react";
// import { getNews } from "../api/api.js";
import { getNewsOneId } from "../api/api";
import { useLocation, Link, useNavigate } from "react-router-dom";
import ScrollTop from "./ScrollTop";
import Content from "../components/ContentHeader";
import Route from "../components/Route";
import queryString from "query-string";

// css
import "../css/NewsPage.css";

// img
import buttonArrow from "../assets/img/news_btn_arrow.png";

function NewsPageOne() {
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const { id } = queryParams;
  const [newsData, setNewsData] = useState(null);
  const { index = 0, renderData = [] } = location.state || {};
  const navigate = useNavigate();

  useEffect(() => {
    const fetch = async () => {
      const result = await getNewsOneId(id);
      setNewsData(result);
    };

    fetch();
  }, [id]);

  const handlePrevClick = () => {
    if (index > 0) {
      const prevItem = renderData[index - 1];
      navigate(`/media/news/detail?id=${prevItem.id}`, {
        state: { index: index - 1, renderData }
      });
    }
  };

  const handleNextClick = () => {
    if (index < renderData.length - 1) {
      const nextItem = renderData[index + 1];
      navigate(`/media/news/detail?id=${nextItem.id}`, {
        state: { index: index + 1, renderData }
      });
    }
  };

  // console.log("아이디 : ", queryParams);
  // console.log(location.search); // 이걸 통해 URL의 쿼리 파라미터를 확인

  return (
    <div className="news_page_detail_wrap">
      <ScrollTop />

      {/* <SideBox /> */}

      <Content
        title="NEWS"
        title1="레인보우브레인의 새로운 소식을 전해드려요"
      ></Content>

      <Route route1="MEDIA" route2="NEWS"></Route>
      <div className="content_bg">
        <div className="container">
          <div className="news_box">
            {newsData ? (
              <>
                <div className="news_box_badge contents3">
                  <span>{newsData.division}</span>
                </div>
                <p className="sub_title2 news_title">{newsData.title}</p>
                <p
                  className="contents2 news_content image ql-editor"
                  dangerouslySetInnerHTML={{
                    __html: newsData.content.replace(/&nbsp;/g, "")
                  }}
                ></p>
              </>
            ) : (
              <p>No news data</p>
            )}

            <div className="news_btn">
              <button
                className="btn_prev contents3"
                onClick={handlePrevClick}
                disabled={index === 0}
              >
                <img src={buttonArrow} alt="이전" />
                Prev
              </button>
              <Link to="/media/news">
                <button className="btn_list contents3 ">목록</button>
              </Link>
              <button
                className="btn_next contents3"
                onClick={handleNextClick}
                disabled={index === renderData.length - 1}
              >
                Next
                <img src={buttonArrow} alt="다음" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewsPageOne;
